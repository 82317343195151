@import 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&amp;family=Roboto:wght@100;300;400;500;700;900&amp;display=swap';

:root {
    --body-font: 'Roboto', sans-serif;
    --heading-font: 'Inter', sans-serif;
    --theme-color: #d9001e;
    --theme-bg-light: #F9F9F9;
    --body-text-color: #757F95;
    --color-white: #ffffff;
    --color-dark: #111111;
    --color-green: #11B76B;
    --color-blue: #0049D0;
    --color-yellow: #FBA707;
    --hero-overlay-color: #01060F;
    --slider-arrow-bg: rgba(255, 255, 255, 0.2);
    --box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
    --box-shadow2: 0 0 15px rgba(0, 0, 0, 0.17);
    --transition: all .5s ease-in-out;
    --transition2: all .3s ease-in-out;
    --border-info-color: rgba(0, 0, 0, 0.08);
    --border-info-color2: rgba(0, 0, 0, 0.05);
    --border-white-color: rgba(255, 255, 255, 0.08);
    --border-white-color2: rgba(255, 255, 255, 0.05);
    --footer-bg: #111111;
    --footer-bg2: #181818;
    --footer-text-color: #F5FAFF
}

*, *:before, *:after {
    box-sizing: inherit
}

* {
    scroll-behavior: inherit !important
}

html, body {
    height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    box-sizing: border-box
}

body {
    font-family: var(--body-font);
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: var(--body-text-color);
    line-height: 1.8
}

a {
    /*color: var(--color-dark);*/
    color: var(--color-white);
    display: inline-block
}

a, a:active, a:focus, a:hover {
    outline: none;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    text-decoration: none
}

a:hover {
    color: var(--color-blue)
}

ul {
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

h1, h2, h3, h4, h5, h6 {
    color: var(--color-dark);
    margin: 0;
    font-weight: 700;
    font-family: var(--heading-font);
    line-height: 1.2
}

h1 {
    font-size: 40px
}

h2 {
    font-size: 35px
}

h3 {
    font-size: 28px
}

h4 {
    font-size: 22px
}

h5 {
    font-size: 18px
}

h6 {
    font-size: 16px
}

p {
    margin: 0
}

.img, img {
    max-width: 100%;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    height: auto
}

label {
    color: #999;
    cursor: pointer;
    font-weight: 400
}

*::-moz-selection {
    background: #d6b161;
    color: var(--color-white);
    text-shadow: none
}

::-moz-selection {
    background: #555;
    color: var(--color-white);
    text-shadow: none
}

::selection {
    background: #555;
    color: var(--color-white);
    text-shadow: none
}

*::-moz-placeholder {
    color: #999;
    font-size: 16px;
    opacity: 1
}

*::placeholder {
    color: #999;
    font-size: 16px;
    opacity: 1
}

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--color-dark);
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center
}

.loader-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px
}

.loader-ripple div {
    position: absolute;
    border: 4px solid var(--theme-color);
    opacity: 1;
    border-radius: 50%;
    animation: loader-ripple 1s cubic-bezier(0, .2, .8, 1) infinite
}

.loader-ripple div:nth-child(2) {
    animation-delay: -.5s
}

@keyframes loader-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1
    }
    100% {
        top: 0;
        left: 0;
        width: 72px;
        height: 72px;
        opacity: 0
    }
}

.ovrflow-hidden {
    overflow: hidden
}

.position-relative {
    position: relative;
    z-index: 1
}

.text-right {
    text-align: right
}

.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.c-pd {
    padding: 0 7rem
}

.s-pd {
    padding: 0 12rem
}

.h-100 {
    height: 100%
}

.h-100vh {
    height: 100vh
}

.bg {
    background: var(--theme-bg-light)
}

.pb-140 {
    padding-bottom: 140px
}

.pd-50 {
    padding: 50px 0
}

.py-120 {
    padding: 120px 0
}

.pt-120 {
    padding-top: 120px
}

.pb-120 {
    padding-bottom: 120px
}

.pt-0 {
    padding-top: 0
}

.pt-10 {
    padding-top: 10px
}

.pt-20 {
    padding-top: 20px
}

.pt-30 {
    padding-top: 30px
}

.pt-40 {
    padding-top: 40px
}

.pt-50 {
    padding-top: 50px
}

.pt-60 {
    padding-top: 60px
}

.pt-70 {
    padding-top: 70px
}

.pt-80 {
    padding-top: 80px
}

.pt-90 {
    padding-top: 90px
}

.pt-100 {
    padding-top: 100px
}

.pb-0 {
    padding-bottom: 0
}

.pb-10 {
    padding-bottom: 10px
}

.pb-20 {
    padding-bottom: 20px
}

.pb-30 {
    padding-bottom: 30px
}

.pb-40 {
    padding-bottom: 40px
}

.pb-50 {
    padding-bottom: 50px
}

.pb-60 {
    padding-bottom: 60px
}

.pb-70 {
    padding-bottom: 70px
}

.pb-80 {
    padding-bottom: 80px
}

.pb-90 {
    padding-bottom: 90px
}

.pb-100 {
    padding-bottom: 100px
}

.mt-0 {
    margin-top: 0
}

.mt-10 {
    margin-top: 10px
}

.mt-20 {
    margin-top: 20px
}

.mt-30 {
    margin-top: 30px
}

.mt-40 {
    margin-top: 40px
}

.mt-50 {
    margin-top: 50px
}

.mt-60 {
    margin-top: 60px
}

.mt-70 {
    margin-top: 70px
}

.mt-80 {
    margin-top: 80px
}

.mt-90 {
    margin-top: 90px
}

.mt-100 {
    margin-top: 100px
}

.mt-120 {
    margin-top: 120px
}

.mt-150 {
    margin-top: 150px
}

.mb-0 {
    margin-bottom: 0
}

.mb-10 {
    margin-bottom: 10px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-40 {
    margin-bottom: 40px
}

.mb-50 {
    margin-bottom: 50px
}

.mb-60 {
    margin-bottom: 60px
}

.mb-70 {
    margin-bottom: 70px
}

.mb-80 {
    margin-bottom: 80px
}

.mb-90 {
    margin-bottom: 90px
}

.mb-100 {
    margin-bottom: 100px
}

.mb-120 {
    margin-bottom: 120px
}

.mb-150 {
    margin-bottom: 150px
}

.mr-300 {
    margin-right: 300px
}

.ml-300 {
    margin-left: 300px
}

.site-heading {
    margin-bottom: 50px;
    position: relative;
    z-index: 1
}

.site-title-tagline {
    position: relative;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 18px;
    font-weight: 700;
    color: var(--theme-color)
}

.site-title-tagline::before {
    content: "";
    position: absolute;
    height: 10px;
    width: 100%;
    background: var(--theme-color);
    opacity: .2;
    left: -2px;
    bottom: 0
}

.site-title {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 45px;
    color: var(--color-dark);
    margin-bottom: 0
}

.site-title span {
    color: var(--theme-color)
}

.site-heading p {
    margin-top: 15px
}

.heading-divider {
    display: inline-block;
    position: relative;
    border: 3px solid var(--theme-color);
    width: 90px;
    height: 10px;
    border-radius: 50px
}

.heading-divider:after {
    content: '';
    position: absolute;
    left: 0;
    top: -1px;
    height: 7px;
    width: 15px;
    border-radius: 50px;
    background: var(--theme-color);
    -webkit-animation: heading-move 5s infinite linear;
    animation: heading-move 5s infinite linear
}

@-webkit-keyframes heading-move {
    0% {
        transform: translateX(-1px)
    }
    50% {
        transform: translateX(72px)
    }
    100% {
        transform: translateX(-1px)
    }
}

@keyframes heading-move {
    0% {
        transform: translateX(-1px)
    }
    50% {
        transform: translateX(72px)
    }
    100% {
        transform: translateX(-1px)
    }
}

.theme-btn {
    font-size: 14px;
    /*color: var(--color-dark);*/
    color: var(--color-white);
    padding: 14px 25px;
    transition: all .5s;
    text-transform: uppercase;
    position: relative;
    border-radius: 50px;
    font-weight: 600;
    letter-spacing: .5px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    border: none;
    background: var(--theme-color);
    box-shadow: var(--box-shadow);
    z-index: 1
}

.theme-btn::before {
    content: "";
    height: 300px;
    width: 300px;
    background: var(--color-dark);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(0);
    transition: .5s cubic-bezier(.25, .46, .45, .94);
    z-index: -1
}

.theme-btn:hover {
    color: var(--color-white)
}

.theme-btn:hover::before {
    transform: translateY(-50%) translateX(-50%) scale(1)
}

.theme-btn i {
    margin-left: 5px
}

.theme-btn span {
    margin-right: 5px
}

.theme-btn2 {
    background: var(--color-white);
    color: var(--color-dark)
}

.theme-btn2::before {
    background: var(--theme-color)
}

.theme-btn2:hover {
    color: var(--color-dark)
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1200px
    }
}

#scroll-top {
    position: fixed;
    bottom: -20px;
    right: 30px;
    z-index: 99;
    font-size: 23px;
    border: none;
    outline: none;
    border-radius: 50px;
    color: var(--color-white);
    background-color: var(--theme-color);
    cursor: pointer;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    box-shadow: var(--box-shadow2);
    transition: var(--transition);
    opacity: 0;
    visibility: hidden;
    z-index: 1
}

#scroll-top.active {
    opacity: 1;
    visibility: visible;
    bottom: 20px
}

@media all and (min-width: 768px) and (max-width: 1199px) {
    #scroll-top.active {
        bottom: 100px
    }
}

.header-top {
    padding: 8px 0 10px;
    position: relative;
    background: var(--color-dark);
    border-bottom: 5px solid var(--theme-color);
    z-index: 1
}

.header-top::before {
    content: "";
    position: absolute;
    background: var(--theme-color);
    border-radius: 0 50px 0 0;
    border-right: 15px solid #fff;
    width: 10%;
    top: 0;
    left: 0;
    bottom: -1px;
    z-index: -1
}

.header-top::after {
    content: "";
    position: absolute;
    background: var(--theme-color);
    border-radius: 50px 0 0 0;
    border-left: 15px solid #fff;
    width: 10%;
    top: 0;
    right: 0;
    bottom: -1px;
    z-index: -1
}

.header-top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.header-top-contact ul {
    display: flex;
    align-items: center;
    gap: 25px
}

.header-top-contact a {
    color: var(--color-white);
    font-weight: 500
}

.header-top-contact a i {
    color: var(--theme-color)
}

.header-top-right {
    display: flex;
    align-items: center;
    gap: 15px
}

.header-top-link a {
    color: var(--color-white);
    margin-right: 12px
}

.header-top-link a:hover {
    color: var(--theme-color)
}

.header-top-social span {
    color: var(--color-white)
}

.header-top-social a {
    color: var(--color-white);
    font-size: 16px;
    text-align: center;
    margin-left: 14px;
    transition: var(--transition)
}

.header-top-social a:hover {
    color: var(--theme-color)
}

@media all and (max-width: 1199px) {
    .header-top-contact ul {
        gap: 10px
    }

    .header-top-social a {
        width: 34px;
        height: 34px;
        line-height: 37px;
        margin-left: 0
    }

    .header-top-left {
        margin-right: 5px
    }
}

@media all and (max-width: 992px) {
    .header-top {
        display: none
    }
    .site-title {
        font-size: 34px;
    }
}

.navbar {
    background: var(--color-white);
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: var(--box-shadow);
    z-index: 999
}

.navbar.fixed-top {
    background: var(--color-white);
    box-shadow: var(--box-shadow2);
    animation: slide-down .7s
}

@keyframes slide-down {
    0% {
        transform: translateY(-100%)
    }
    100% {
        transform: translateY(0)
    }
}

.navbar .navbar-brand .logo-display {
    display: block
}

.navbar .navbar-brand .logo-scrolled {
    display: none
}

.navbar.fixed-top .navbar-brand .logo-display {
    display: none
}

.navbar.fixed-top .navbar-brand .logo-scrolled {
    display: block
}

.navbar .navbar-toggler:focus {
    outline: none;
    box-shadow: none
}

.navbar-toggler-mobile-icon {
    display: inline-block;
    width: inherit;
    height: inherit
}

.navbar-brand {
    margin-right: 0
}

.navbar-brand img {
    width: 180px
}

.navbar .dropdown-toggle::after {
    display: inline-block;
    margin-left: 5px;
    vertical-align: baseline;
    font-family: 'font awesome 6 pro';
    content: "\f107";
    font-weight: 600;
    border: none;
    font-size: 14px
}

@media all and (max-width: 1199px) {
    .nav-right {
        margin-left: 25px !important
    }

    .navbar .nav-item .nav-link {
        margin-right: 15px
    }

    .navbar .nav-right-btn {
        display: none
    }
}

@media all and (min-width: 992px) {
    .navbar .nav-item .nav-link {
        margin-right: 22px;
        padding: 30px 0;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 1px;
        color: var(--color-dark);
        text-transform: uppercase
    }

    .navbar .nav-item:last-child .nav-link {
        margin-right: 0
    }

    .navbar .nav-item .dropdown-menu {
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
        margin-top: 0;
        border: none;
        left: -15px;
        border-radius: 8px;
        background: var(--color-white);
        width: 220px;
        box-shadow: var(--box-shadow)
    }

    .navbar .nav-item .dropdown-menu li {
        border-bottom: 1px solid var(--border-info-color)
    }

    .navbar .nav-item .dropdown-menu li:last-child {
        margin-bottom: 0;
        border-bottom: none
    }

    .navbar .nav-item .dropdown-menu .dropdown-item {
        font-size: 14px;
        padding: 9px 25px;
        font-weight: 600;
        letter-spacing: 1px;
        color: var(--color-dark);
        position: relative;
        overflow: hidden;
        text-transform: uppercase;
        transition: all .3s ease-in-out
    }

    .navbar .nav-item .dropdown-menu .dropdown-item:hover {
        background: 0 0;
        color: var(--theme-color);
        padding-left: 32px
    }

    .navbar .nav-item .dropdown-menu .dropdown-item::before {
        content: "//";
        position: absolute;
        left: 15px;
        top: 9px;
        color: var(--theme-color);
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
        z-index: -1
    }

    .navbar .nav-item .dropdown-menu .dropdown-item:hover::before {
        opacity: 1;
        visibility: visible
    }

    .navbar .nav-item .nav-link {
        position: relative
    }

    .navbar .nav-item .nav-link.active, .navbar .nav-item:hover .nav-link {
        color: var(--theme-color)
    }

    .navbar .nav-item:hover .dropdown-menu {
        transition: .3s;
        opacity: 1;
        visibility: visible;
        top: 100%;
        transform: rotateX(0deg)
    }

    .navbar .dropdown-menu-end {
        right: 0;
        left: auto
    }

    .navbar .dropdown-menu.fade-down {
        top: 80%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%
    }

    .navbar .dropdown-menu.fade-up {
        top: 140%
    }

    .navbar #main_nav {
        justify-content: flex-end
    }

    .nav-right {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-left: 45px
    }

    .nav-right-link {
        position: relative;
        font-size: 20px;
        color: var(--color-dark);
        transition: var(--transition)
    }

    .nav-right-link:hover {
        color: var(--theme-color) !important
    }

    .nav-right .sidebar-btn .nav-right-link, .nav-right .search-btn .nav-right-link {
        border: none;
        background: 0 0;
        color: var(--color-dark);
        font-size: 28px;
        padding-right: 0
    }

    .nav-right .search-btn .nav-right-link {
        font-size: 20px;
        padding: 0
    }

    .nav-right .theme-btn {
        padding: 12px 25px
    }
}

.mobile-menu-right {
    display: none
}

@media all and (max-width: 991px) {
    .navbar {
        top: 0;
        right: 0;
        left: 0;
        position: fixed
    }

    .navbar-brand {
        padding-left: 10px
    }

    .navbar-brand img {
        width: 130px
    }

    .navbar-collapse {
        max-height: 220px;
        overflow: hidden;
        overflow-y: auto;
        padding: 0 20px;
        background-color: var(--color-white)
    }

    .dropdown-toggle::after {
        float: right
    }

    .navbar .nav-item .nav-link {
        color: var(--color-dark);
        font-weight: 700;
        transition: var(--transition)
    }

    .navbar .nav-item .nav-link:hover {
        color: var(--theme-color) !important
    }

    .navbar-toggler {
        padding: 0;
        border: none
    }

    .mobile-menu-right {
        display: flex;
        align-items: center;
        gap: 20px
    }

    .mobile-menu-right .nav-right-link {
        background: 0 0;
        border: none;
        font-size: 20px;
        color: var(--color-dark)
    }

    .mobile-menu-right .nav-right-link:hover {
        color: var(--theme-color)
    }

    .search-area.open {
        top: 50px !important
    }

    .navbar-toggler-mobile-icon {
        font-size: 25px;
        color: var(--color-dark);
        font-weight: 500
    }

    .navbar .dropdown-menu {
        border-radius: 8px
    }

    .nav-right {
        display: none
    }
}

.navbar .nav-item .dropdown-submenu {
    position: relative
}

.navbar .nav-item .dropdown-submenu .dropdown-menu::before {
    display: none
}

.navbar .nav-item .dropdown-submenu a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 15px;
    top: 8px;
    font-weight: 600
}

.navbar .nav-item .dropdown-submenu a:hover {
    background: 0 0;
    color: var(--color-white)
}

.navbar .nav-item .dropdown-submenu .dropdown-menu {
    top: 120%;
    left: 100%;
    opacity: 0;
    visibility: hidden
}

.navbar .nav-item .dropdown-submenu:hover .dropdown-menu {
    top: 0;
    opacity: 1;
    visibility: visible
}

@media all and (max-width: 991px) {
    .navbar .nav-item .dropdown-submenu .dropdown-menu {
        margin: 0 17px
    }

    .navbar .nav-item .dropdown-submenu .dropdown-menu {
        opacity: unset;
        visibility: unset
    }

    .navbar .nav-item .dropdown-submenu a::after {
        top: 4px
    }

    .navbar .nav-item .dropdown-submenu a:hover {
        color: var(--theme-color)
    }
}

.search-area {
    position: absolute;
    top: 85px;
    right: 0;
    background: var(--color-white);
    padding: 15px;
    -webkit-box-shadow: 0 5px 15px rgb(0 0 0/10%);
    box-shadow: 0 5px 15px rgb(0 0 0/10%);
    width: 340px;
    visibility: hidden;
    opacity: 0;
    border-radius: 10px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    z-index: 1
}

.search-area.open {
    visibility: visible;
    opacity: 1;
    top: 78px
}

.search-area .form-group {
    position: relative
}

.search-area .form-control {
    padding: 12px 45px 12px 20px;
    border-radius: 10px;
    box-shadow: none
}

.search-area .form-control:focus {
    border-color: var(--theme-color)
}

.search-area .search-icon-btn {
    position: absolute;
    right: 8px;
    top: 6px;
    background: 0 0;
    border: none;
    font-size: 20px
}

.sidebar-popup {
    position: fixed;
    top: 0;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, .8);
    width: 100%;
    height: 100%;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: var(--transition);
    transition: var(--transition)
}

.sidebar-popup.open {
    visibility: visible;
    opacity: 1
}

.sidebar-wrapper {
    position: fixed;
    top: 0;
    right: -100%;
    width: 450px;
    background: var(--color-white);
    visibility: hidden;
    opacity: 0;
    padding: 40px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    z-index: 9999
}

.sidebar-wrapper.open {
    right: 0;
    visibility: visible;
    opacity: 1
}

.sidebar-content {
    position: relative;
    overflow-y: auto;
    height: calc(100vh - 75px)
}

.close-sidebar-popup {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 38px;
    height: 38px;
    line-height: 36px;
    border-radius: 50px;
    text-align: center;
    border: none;
    font-size: 20px;
    background: var(--color-dark);
    color: var(--color-white);
    box-shadow: var(--box-shadow);
    transition: var(--transition)
}

.close-sidebar-popup:hover {
    background: var(--theme-color)
}

.sidebar-logo img {
    width: 220px
}

.sidebar-about {
    margin-top: 40px
}

.sidebar-about h4 {
    font-size: 19px;
    text-transform: uppercase;
    margin-bottom: 10px
}

.sidebar-contact {
    margin-top: 20px
}

.sidebar-contact h4 {
    font-size: 19px;
    text-transform: uppercase;
    margin-bottom: 15px
}

.sidebar-contact li {
    margin: 10px 0
}

.sidebar-contact li i {
    margin-right: 5px;
    color: var(--theme-color)
}

.sidebar-contact li a:hover {
    color: var(--theme-color)
}

.sidebar-social {
    margin-top: 25px
}

.sidebar-social h4 {
    margin-bottom: 20px;
    font-size: 19px;
    text-transform: uppercase
}

.sidebar-social a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50px;
    margin-right: 8px;
    background: var(--color-dark);
    color: var(--color-white);
    box-shadow: var(--box-shadow)
}

.sidebar-social a:hover {
    background: var(--theme-color)
}

.main {
    margin-top: 0
}

.hero-section {
    position: relative
}

.hero-single {
    padding-top: 180px;
    padding-bottom: 180px;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1
}

.hero-single::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: -.5px;
    top: 0;
    background: var(--hero-overlay-color);
    opacity: .7;
    z-index: -1
}

.hero-single .hero-content {
    height: 100%
}

.hero-single .hero-content .hero-title {
    color: var(--color-white);
    font-size: 60px;
    font-weight: 800;
    margin: 20px 0;
    text-transform: uppercase
}

.hero-single .hero-content .hero-title span {
    color: var(--theme-color)
}

.hero-single .hero-content .hero-sub-title {
    display: inline-block;
    color: var(--theme-color);
    font-size: 25px;
    letter-spacing: 6px;
    font-weight: 800;
    position: relative;
    text-transform: uppercase
}

.hero-single .hero-content p {
    color: var(--color-white);
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 20px
}

.hero-single .hero-content .hero-btn {
    gap: 1rem;
    display: flex;
    margin-top: 35px;
    justify-content: start
}

.hero-slider.owl-theme .owl-nav {
    margin-top: 0
}

.hero-slider.owl-theme .owl-nav [class*=owl-] {
    color: var(--color-white);
    font-size: 25px;
    margin: 0;
    padding: 0;
    background: var(--slider-arrow-bg);
    display: inline-block;
    cursor: pointer;
    height: 60px;
    width: 60px;
    line-height: 60px;
    border-radius: 50px;
    text-align: center;
    transition: var(--transition)
}

.hero-slider.owl-theme .owl-nav [class*=owl-]:hover {
    background: var(--color-white);
    color: var(--theme-color)
}

.hero-slider.owl-theme .owl-nav .owl-prev {
    left: 40px
}

.hero-slider.owl-theme .owl-nav .owl-next {
    right: 40px
}

.hero-slider.owl-theme .owl-nav .owl-prev, .hero-slider.owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%)
}

@media all and (max-width: 1199px) {
    .hero-single .hero-content .hero-title {
        font-size: 37px
    }

    .hero-slider.owl-theme .owl-nav .owl-prev, .hero-slider.owl-theme .owl-nav .owl-next {
        top: unset;
        bottom: 70px !important
    }

    .hero-slider.owl-theme .owl-nav .owl-prev {
        left: unset;
        right: 120px
    }

    .hero-slider.owl-theme .owl-nav .owl-next {
        right: 40px
    }
}

@media all and (max-width: 991px) {
    .hero-single .hero-content .hero-title {
        font-size: 50px
    }
}

@media all and (max-width: 767px) {
    .hero-single .hero-content .hero-sub-title {
        font-size: 18px
    }

    .hero-single .hero-content .hero-btn {
        gap: 1rem
    }
}

.play-btn {
    display: inline-block;
    padding: 0;
    height: 75px;
    width: 75px;
    line-height: 75px;
    font-size: 20px;
    text-align: center;
    background: var(--theme-color);
    color: var(--color-white) !important;
    position: absolute;
    border-radius: 50%;
    z-index: 1
}

.play-btn i::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--theme-color);
    border-radius: 50px;
    animation: ripple-wave 1s linear infinite;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: all .5s ease-in-out
}

@keyframes ripple-wave {
    0% {
        opacity: .8;
        -webkit-transform: scale(.9);
        transform: scale(.9)
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
}

.nice-select {
    width: 100%;
    height: 55px;
    line-height: 54px;
    border-radius: 10px;
    font-size: 16px;
    border-color: #ced4da;
    color: var(--body-text-color)
}

.nice-select::after {
    width: 9px;
    height: 9px;
    right: 20px;
    margin-top: -7px
}

.nice-select:focus {
    border-color: var(--theme-color)
}

.nice-select .list {
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 40px 5px rgb(0 0 0/5%)
}

.nice-select .option.focus, .nice-select .option.selected.focus, .nice-select .option:hover {
    border-radius: 8px;
    background: rgba(255, 179, 0, .1);
    color: var(--theme-color)
}

.booking-area {
    position: relative;
    margin-top: -190px;
    z-index: 2
}

.booking-form {
    padding: 30px;
    background: var(--color-white);
    border-radius: 20px;
    box-shadow: var(--box-shadow)
}

.booking-title {
    font-size: 25px;
    margin-bottom: 10px
}

.booking-form .form-group {
    margin-top: 10px;
    position: relative
}

.booking-form .form-group i {
    position: absolute;
    right: 11px;
    bottom: 9px;
}

.booking-form .form-group label {
    margin-bottom: 2px;
    color: var(--color-dark)
}

.booking-form .form-select, .booking-form .form-control {
    /*padding: 14px 50px 14px 20px;*/
    /*border-radius: 12px;*/
    /*font-size: 18px;*/
    /*box-shadow: none;*/
    /*color: var(--body-text-color)*/
}

.booking-form .form-select:focus, .booking-form .form-control:focus {
    border-color: var(--theme-color)
}

.booking-form .theme-btn {
    width: 100%;
    padding: 15px
}

.booking-form .theme-btn::before {
    width: 360px;
    height: 360px
}

@media all and (max-width: 991px) {
    .booking-form {
        padding: 25px
    }

    .booking-form .theme-btn {
        margin-top: 25px
    }
}

.ui-datepicker .ui-datepicker-header .ui-corner-all > span, .ui-datepicker .ui-datepicker-header .ui-state-hover > span {
    display: none
}

.ui-datepicker.ui-widget-content {
    border: none;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0 3px 24px rgb(0 0 0/10%)
}

.ui-datepicker .ui-datepicker-header .ui-corner-all, .ui-datepicker .ui-datepicker-header .ui-state-hover {
    cursor: pointer;
    border: 0;
    background: 0 0;
    font-weight: 500;
    top: 3px
}

.ui-datepicker .ui-datepicker-prev:after {
    content: "\f100";
    left: 5px;
    top: 0;
    position: absolute;
    color: var(--color-white);
    font-family: "font awesome 6 pro"
}

.ui-datepicker .ui-datepicker-next:after {
    content: "\f101";
    right: 5px;
    top: 0;
    position: absolute;
    color: var(--color-white);
    font-family: "font awesome 6 pro"
}

.ui-datepicker .ui-widget-header {
    background: var(--theme-color);
    color: var(--color-white);
    border: none;
    border-radius: 0px
}

.ui-datepicker td span, .ui-datepicker td a {
    display: block;
    padding: .2em;
    text-align: center;
    text-decoration: none
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: none;
    border-radius: 8px
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
    border: none;
    background: rgba(255, 179, 0, .6);
    color: var(--color-white)
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
    background: var(--theme-color);
    color: var(--color-white)
}

.ui-timepicker-standard {
    border: none !important;
    background: var(--color-white);
    border-radius: 10px;
    box-shadow: 0 3px 24px rgb(0 0 0/10%);
    z-index: 2 !important
}

.ui-timepicker-standard a {
    border: none !important;
    transition: none !important;
    border-radius: 8px
}

.ui-timepicker-standard a:hover {
    background: var(--theme-color);
    color: var(--color-white)
}

.about-left {
    position: relative
}

.about-img img {
    border-radius: 15px
}

.about-experience {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 10px;
    top: 0;
    left: 0;
    background: var(--color-dark);
    border-radius: 50px;
    padding: 10px 30px 10px 10px;
    color: var(--color-white);
    box-shadow: 0 0 40px 5px rgb(0 0 0/10%)
}

.about-experience-icon {
    color: var(--color-white);
    font-size: 45px;
    width: 70px;
    height: 70px;
    line-height: 63px;
    text-align: center;
    background: var(--theme-color);
    border-radius: 50px
}

.about-experience-icon img {
    width: 50px;
    filter: brightness(0) invert(1)
}

.about-right {
    position: relative;
    display: block;
    padding-left: 30px
}

.about-list-wrapper {
    position: relative;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px
}

.about-list {
    position: relative;
    display: block
}

.about-list li {
    position: relative;
    padding-left: 25px;
    margin-top: 10px;
    font-weight: 500
}

.about-list li::before {
    content: "\f058";
    position: absolute;
    left: 0;
    top: 0;
    font-family: "font awesome 6 pro";
    color: var(--theme-color);
    font-weight: 700
}

@media all and (max-width: 991px) {
    .about-right {
        margin-top: 30px
    }
}

.service-area {
    position: relative;
    display: block;
    overflow: hidden
}

.service-item {
    position: relative;
    padding: 0 20px 20px;
    margin-bottom: 25px;
    transition: var(--transition);
    z-index: 1
}

.service-item::before {
    content: "";
    position: absolute;
    background: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    width: 100%;
    left: 0;
    top: 130px;
    bottom: 0;
    z-index: -1
}

.service-item:hover {
    transform: translateY(-10px)
}

.service-img img {
    border-radius: 30px
}

.service-content {
    position: relative;
    margin-top: 5px
}

.service-icon {
    position: absolute;
    width: 90px;
    height: 90px;
    line-height: 82px;
    background: var(--theme-color);
    font-size: 50px;
    color: var(--color-white);
    text-align: center;
    border-radius: 50%;
    margin-top: -50px;
    right: 40px;
    box-shadow: var(--box-shadow);
    z-index: 1
}

.service-icon img {
    width: 50px
}

.service-arrow {
    margin-top: 25px
}

.service-title a {
    font-size: 22px;
    margin: 20px 0;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--color-dark)
}

.service-title a:hover {
    color: var(--theme-color)
}

.service-arrow .theme-btn {
    padding: 10px 22px
}

.service-single-list i {
    color: var(--theme-color);
    margin-right: 10px
}

.service-download a {
    border: 2px solid var(--theme-color);
    padding: 12px 20px;
    color: var(--color-dark);
    display: block;
    margin-bottom: 20px;
    font-weight: 600;
    border-radius: 50px;
    transition: var(--transition)
}

.service-download a i {
    margin-right: 10px
}

.service-download a:hover {
    background-color: var(--theme-color);
    color: var(--color-white)
}

.service-details h3 {
    color: var(--color-dark)
}

.service-details img {
    border-radius: 15px
}

.feature-area {
    position: relative;
    z-index: 1
}

.feature-bg {
    background-image: url(/assets/img/feature/01.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden
}

.feature-bg::before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, .75);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

.feature-bg::after {
    content: "";
    position: absolute;
    background-image: url(/assets/img/shape/shape-2.png);
    background-repeat: repeat-x;
    width: 500%;
    height: 50px;
    bottom: -10px;
    left: 0;
    animation: slide 100s linear infinite
}

@keyframes slide {
    100% {
        transform: translateX(-3400px)
    }
}

.feature-item {
    padding: 25px 20px;
    text-align: center;
    position: relative;
    background: var(--color-white);
    border-radius: 15px;
    margin-bottom: 25px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
    transition: var(--transition);
    z-index: 1
}

.feature-item:hover {
    transform: translateY(-10px)
}

.feature-icon {
    width: 100px;
    height: 100px;
    line-height: 85px;
    border-radius: 50px;
    margin: 0 auto 20px;
    border: 5px solid var(--color-dark);
    background: var(--theme-color);
    transition: var(--transition)
}

.feature-icon img {
    width: 55px
}

.feature-item:hover .feature-icon {
    transform: rotateY(360deg)
}

.feature-item h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px
}

@media all and (max-width: 1199px) {
    .feature-area {
        margin-top: -20px
    }
}

.video-content {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 20px
}

.video-content::before {
    content: "";
    position: absolute;
    background: rgba(3, 2, 7, .2);
    border-radius: 20px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

.video-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    height: 550px;
    z-index: 100
}

.video-wrapper img {
    border-radius: 12px
}

.video-area .play-btn {
    display: inline-block;
    padding: 0;
    height: 75px;
    width: 75px;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

@media all and (max-width: 767px) {
    .video-wrapper {
        height: 250px
    }
}

.choose-area {
    position: relative;
    background: var(--color-dark);
    z-index: 1
}

.choose-area::before {
    content: "";
    position: absolute;
    background-image: url(/assets/img/shape/shape-6.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: .04;
    z-index: -1
}

.choose-item {
    display: flex;
    gap: 15px;
    position: relative;
    background: var(--color-white);
    border-radius: 25px;
    margin-bottom: 25px;
    padding: 20px;
    box-shadow: var(--box-shadow);
    z-index: 1
}

.choose-count {
    position: absolute;
    right: 20px;
    top: -10px;
    font-size: 50px;
    font-weight: 800;
    -webkit-text-stroke: 1px var(--theme-color);
    -webkit-text-fill-color: transparent;
    z-index: -1
}

.choose-item-icon {
    width: 90px;
    height: 90px;
    line-height: 80px;
    background: var(--theme-color);
    color: var(--color-white);
    font-size: 45px;
    border-radius: 50px;
    margin-bottom: 15px;
    text-align: center
}

.choose-item-icon img {
    width: 55px
}

.choose-item-info {
    flex: 1
}

.choose-item-info h3 {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 600
}

@media all and (max-width: 991px) {
    .choose-content-wrapper {
        margin-top: 40px
    }
}

@media all and (max-width: 767px) {
    .choose-item {
        flex-direction: column
    }
}

.testimonial-area {
    position: relative;
    background-image: url(/assets/img/testimonial/bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    z-index: 1
}

.testimonial-area::before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, .85);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1
}

.testimonial-single {
    margin-bottom: 20px;
    background: var(--color-white);
    border-radius: 20px;
    padding: 25px;
    position: relative;
    z-index: 1
}

.testimonial-single::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    right: 10px;
    top: 10px;
    border: 3px solid var(--theme-color);
    border-left: none;
    border-bottom: none;
    border-radius: 0 20px 0 0;
    transition: var(--transition);
    z-index: -1
}

.testimonial-single:hover::before {
    width: 80%;
    height: 80%
}

.testimonial-content {
    display: flex;
    align-items: center
}

.testimonial-quote p {
    color: var(--color-dark)
}

.testimonial-author-info {
    flex: 1;
    margin: 15px 0 10px
}

.testimonial-author-img {
    margin-right: 10px;
    width: 70px;
    padding: 5px;
    border-radius: 50px;
    border: 3px solid var(--theme-color);
    border-top-color: transparent;
    border-bottom-color: transparent
}

.testimonial-author-img img {
    border-radius: 50%
}

.testimonial-author-info h4 {
    font-size: 18px;
    color: var(--color-dark)
}

.testimonial-author-info p {
    color: var(--theme-color);
    font-weight: 500
}

.testimonial-quote-icon {
    position: absolute;
    right: 40px;
    bottom: -15px;
    font-size: 120px;
    color: var(--theme-color);
    opacity: .2
}

.testimonial-area .owl-dots {
    text-align: center;
    margin-top: 30px
}

.testimonial-area .owl-dots .owl-dot span {
    background: var(--theme-color);
    margin: 5px;
    border-radius: 50px;
    width: 8px;
    height: 8px;
    display: inline-block;
    transition: var(--transition)
}

.testimonial-area .owl-dots .owl-dot.active span {
    background-color: var(--theme-color);
    width: 20px
}

.testimonial-rate {
    color: var(--theme-color);
    margin-top: 12px
}

.counter-area {
    position: relative;
    z-index: 2
}

.counter-wrapper {
    background-image: url(/assets/img/shape/shape-3.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: -150px;
    border-radius: 30px;
    box-shadow: var(--box-shadow)
}

.counter-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    position: relative;
    z-index: 1
}

.counter-box .icon {
    position: relative;
    text-align: center;
    font-size: 60px;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    line-height: 100px;
    color: var(--color-white);
    border: 5px solid var(--color-white);
    background: var(--theme-color);
    z-index: 1
}

.counter-box .icon img {
    width: 70px
}

.counter-box .counter {
    display: block;
    line-height: 1;
    color: var(--color-white);
    font-size: 50px;
    font-weight: 600
}

.counter-box .title {
    color: var(--color-white);
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize
}

@media all and (max-width: 991px) {
    .counter-area .counter-box {
        margin: 40px 0
    }
}

.cta-area {
    background: var(--theme-color);
    position: relative;
    padding: 120px 0;
    z-index: 1
}

.cta-area::before {
    content: "";
    position: absolute;
    background-image: url(/assets/img/shape/shape-5.png);
    background-repeat: repeat-x;
    width: 100%;
    height: 50px;
    left: 0;
    top: 0;
    z-index: -1
}

.cta-area::after {
    content: "";
    position: absolute;
    background-image: url(/assets/img/shape/shape-5.png);
    background-repeat: repeat-x;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: -10px;
    z-index: -1
}

.cta-text h1 {
    color: var(--color-white);
    font-size: 50px;
    text-transform: capitalize;
    margin-bottom: 15px
}

.cta-text p {
    color: var(--color-white)
}

.cta-number {
    font-size: 32px;
    color: var(--color-white) !important;
    font-weight: 700
}

.cta-number i {
    margin-right: 15px
}

.cta-btn .theme-btn {
    background: var(--color-dark);
    color: var(--color-white)
}

.cta-btn .theme-btn:hover::before {
    background: var(--color-white)
}

.cta-btn .theme-btn:hover {
    color: var(--color-dark)
}

.cta-divider {
    position: relative
}

.cta-divider::before {
    position: absolute;
    content: close-quote;
    width: 2px;
    bottom: 0;
    background-color: var(--color-white);
    right: -118px;
    top: 0
}

.cta-divider:after {
    width: 0;
    position: absolute;
    content: close-quote;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid var(--color-white);
    top: 50%;
    transform: translateY(-50%);
    right: -124px
}

.blog-area {
    position: relative
}

.blog-item {
    padding: 20px;
    margin-bottom: 25px;
    background: var(--color-white);
    border-radius: 15px;
    box-shadow: var(--box-shadow);
    transition: var(--transition)
}

.blog-item-img {
    border-radius: 15px;
    overflow: hidden
}

.blog-item-img img {
    border-radius: 15px
}

.blog-item:hover .blog-item-img img {
    transform: scale(1.1)
}

.blog-item-info {
    padding: 15px 0 0
}

.blog-item-meta ul {
    margin: 0;
    padding: 0;
    margin-bottom: 14px;
    border-bottom: 1px solid var(--border-info-color);
    padding-bottom: 18px
}

.blog-item-meta ul li {
    display: inline-block;
    margin-right: 15px;
    font-weight: 500;
    position: relative;
    color: var(--color-dark)
}

.blog-item-meta ul li i {
    margin-right: 5px;
    color: var(--theme-color)
}

.blog-item-meta a:hover {
    color: var(--theme-color)
}

.blog-title {
    font-size: 22px;
    margin-bottom: 15px;
    text-transform: capitalize
}

.blog-item-info p {
    margin-bottom: 16px
}

.blog-item-info .theme-btn {
    margin-top: 15px
}

.blog-item-info h4 a {
    color: var(--color-dark)
}

.blog-item-info h4 a:hover {
    color: var(--theme-color)
}

.blog-thumb-img {
    margin-bottom: 20px
}

.blog-single-content img {
    border-radius: 15px
}

.blog-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px
}

.blog-meta .blog-meta-left ul {
    display: flex;
    align-items: center;
    gap: 20px
}

.blog-meta .blog-meta-left ul li {
    font-weight: 500
}

.blog-meta i {
    margin-right: 5px;
    color: var(--theme-color)
}

.blog-meta a {
    color: var(--body-text-color);
    font-weight: 500
}

.blog-meta a:hover {
    color: var(--theme-color)
}

.blog-details-title {
    font-size: 34px;
    color: var(--color-dark)
}

.blockqoute {
    background: var(--theme-bg-light);
    border-left: 5px solid var(--theme-color);
    padding: 30px;
    font-size: 17px;
    font-style: italic;
    margin: 20px 0;
    border-radius: 0;
    position: relative;
    z-index: 1
}

.blockqoute::before {
    content: "\f10e";
    position: absolute;
    right: 150px;
    top: -20px;
    font-size: 140px;
    font-family: 'font awesome 6 pro';
    color: var(--theme-color);
    opacity: .2;
    z-index: -1
}

.blockqoute-author {
    margin-top: 20px;
    padding-left: 60px;
    position: relative;
    color: var(--color-dark)
}

.blockqoute-author::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 40px;
    background: var(--theme-color);
    left: 0;
    top: 10px
}

.blog-details-tags {
    display: flex;
    align-items: center;
    gap: 20px
}

.blog-details-tags h5 {
    color: var(--color-dark)
}

.blog-details-tags ul {
    display: flex;
    align-items: center;
    gap: 15px
}

.blog-details-tags ul a {
    background: var(--theme-bg-light);
    color: var(--color-dark);
    padding: 3px 18px;
    border-radius: 50px;
    transition: var(--transition)
}

.blog-details-tags ul a:hover {
    background: var(--theme-color);
    color: var(--color-white)
}

.blog-author {
    display: flex;
    justify-content: start;
    align-items: center;
    background: var(--theme-bg-light);
    border-radius: 15px;
    margin: 50px 0;
    padding: 20px
}

.blog-author-img {
    width: 350px;
    padding: 8px;
    border: 5px solid transparent;
    border-right-color: var(--theme-color);
    border-radius: 50%
}

.blog-author-img img {
    border-radius: 50%
}

.author-name {
    font-size: 22px;
    color: var(--theme-color);
    margin: 8px 0
}

.author-info {
    padding: 0 20px
}

.author-social {
    margin-top: 10px
}

.author-social a {
    width: 35px;
    height: 35px;
    line-height: 31px;
    text-align: center;
    border: 2px solid var(--theme-color);
    border-radius: 50px;
    margin-right: 5px;
    color: var(--theme-color);
    transition: var(--transition)
}

.author-social a:hover {
    color: var(--color-white);
    background: var(--theme-color)
}

.blog-comments {
    margin-bottom: 50px
}

.blog-comments h3 {
    color: var(--color-dark)
}

.blog-comments-wrapper {
    margin: 30px 0
}

.blog-comments-single {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    margin-top: 50px
}

.blog-comments-single img {
    border-radius: 50%
}

.blog-comments-content {
    padding: 0 0 0 20px
}

.blog-comments-content span {
    font-size: 14px;
    color: var(--theme-color);
    font-weight: 500
}

.blog-comments-content a {
    font-weight: 500;
    margin-top: 5px;
    color: var(--theme-color)
}

.blog-comments-content a:hover {
    color: var(--color-dark)
}

.blog-comments-content h5 {
    color: var(--color-dark)
}

.blog-comments-reply {
    margin-left: 50px
}

.blog-comments-form {
    padding: 30px;
    margin-top: 50px;
    border-radius: 10px;
    background: var(--theme-bg-light)
}

.blog-comments-form h3 {
    margin-bottom: 20px
}

.blog-comments-form .form-group {
    margin-bottom: 20px
}

.blog-comments-form .form-control {
    padding: 15px 20px;
    border-radius: 12px;
    box-shadow: none;
    transition: var(--transition)
}

.blog-comments-form .form-control:focus {
    border-color: var(--theme-color)
}

@media all and (max-width: 767px) {
    .blog-meta {
        flex-direction: column;
        font-size: 15px
    }

    .blog-meta .blog-meta-left ul {
        gap: 10px
    }

    .blog-details-tags {
        flex-direction: column;
        align-items: flex-start
    }

    .blog-author {
        flex-direction: column;
        text-align: center;
        padding: 25px
    }

    .author-info {
        margin-top: 25px
    }

    .blog-author-img {
        border: none
    }

    .blog-comments-single {
        flex-direction: column;
        text-align: center;
        padding: 30px 0;
        box-shadow: var(--box-shadow);
        margin-bottom: 30px;
        border-radius: 10px
    }

    .blog-comments-single img {
        margin: 0 auto 20px
    }

    .blog-comments-reply {
        margin-left: 0
    }
}

.widget {
    background: var(--theme-bg-light);
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px
}

.widget .widget-title {
    padding-bottom: 10px;
    margin-bottom: 30px;
    position: relative;
    font-size: 22px;
    color: var(--color-dark)
}

.widget .widget-title::before {
    position: absolute;
    content: '';
    width: 15px;
    border-bottom: 3px solid var(--theme-color);
    bottom: 0;
    left: 0
}

.widget .widget-title::after {
    position: absolute;
    content: '';
    width: 30px;
    border-bottom: 3px solid var(--theme-color);
    bottom: 0;
    left: 22px
}

.widget .search-form .form-control {
    padding: 12px 15px;
    border-radius: 12px;
    box-shadow: none
}

.widget .search-form {
    position: relative
}

.widget .search-form .form-control:focus {
    border-color: var(--theme-color)
}

.widget .search-form button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 18px;
    padding: 8px 18px 6px;
    background: 0 0;
    border: none;
    color: var(--theme-color)
}

.widget .category-list a {
    display: block;
    padding: 10px 0;
    font-weight: 500;
    border-bottom: 1px solid #ced4da;
    transition: var(--transition)
}

.widget .category-list a:last-child {
    margin-bottom: 0;
    border-bottom: none
}

.widget .category-list a:hover {
    padding-left: 10px;
    color: var(--theme-color)
}

.widget .category-list a i {
    margin-right: 5px;
    color: var(--theme-color)
}

.widget .category-list a span {
    float: right
}

.widget .recent-post-single {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px
}

.widget .recent-post-img {
    margin-right: 20px
}

.widget .recent-post-img img {
    width: 120px;
    border-radius: 8px
}

.widget .recent-post-bio h6 {
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize
}

.widget .recent-post-bio span {
    font-size: 14px;
    color: var(--theme-color)
}

.widget .recent-post-bio span i {
    margin-right: 5px
}

.widget .recent-post-bio h6 a:hover {
    color: var(--theme-color)
}

.widget .social-share-link a {
    width: 35px;
    height: 35px;
    line-height: 31px;
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    text-align: center;
    margin-right: 5px;
    border-radius: 50px;
    transition: var(--transition)
}

.widget .social-share-link a:hover {
    background: var(--theme-color);
    color: var(--color-white)
}

.widget .tag-list a {
    background: var(--color-white);
    color: var(--color-dark);
    padding: 5px 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 50px;
    display: inline-block;
    transition: var(--transition)
}

.widget .tag-list a:hover {
    background-color: var(--theme-color);
    color: var(--color-white)
}

.contact-wrapper {
    background: var(--color-white);
    border-radius: 10px;
    padding: 20px;
    box-shadow: var(--box-shadow)
}

.contact-img {
    position: relative
}

.contact-img::before {
    content: "";
    position: absolute;
    border: 6px solid var(--theme-color);
    border-radius: 25px 50% 50% 50%;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

.contact-img img {
    width: 100%;
    padding: 15px;
    border-radius: 40px 50% 50% 50%
}

.contact-form-header {
    margin-bottom: 30px
}

.contact-form-header h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--color-dark)
}

.contact-form .form-group {
    margin-bottom: 25px
}

.contact-form .form-group .form-control {
    padding: 15px 20px;
    border-radius: 12px;
    box-shadow: none;
    transition: var(--transition)
}

.contact-form .form-group .form-control:focus {
    border-color: var(--theme-color)
}

.contact-map {
    margin-bottom: -9px
}

.contact-map iframe {
    width: 100%;
    height: 450px
}

.contact-content {
    margin-bottom: 50px
}

.contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
    padding: 30px 20px;
    position: relative;
    margin-bottom: 25px;
    border-radius: 20px;
    background: var(--color-white);
    box-shadow: var(--box-shadow);
    transition: var(--transition)
}

.contact-info::before {
    content: "";
    position: absolute;
    width: 80px;
    height: 80px;
    border: 3px solid var(--theme-color);
    border-left: none;
    border-bottom: none;
    border-top-right-radius: 20px;
    right: 15px;
    top: 15px;
    transition: var(--transition)
}

.contact-info:hover::before {
    width: 80%;
    height: 80%
}

.contact-info:hover {
    transform: translateY(-8px)
}

.contact-info-icon i {
    font-size: 35px;
    color: var(--color-white);
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 50px;
    background: var(--theme-color)
}

.contact-info h5 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    color: var(--color-dark)
}

.contact-info p {
    color: var(--color-dark);
    font-weight: 500;
    font-size: 16px
}

@media all and (max-width: 768px) {
    .contact-content {
        margin-top: 50px;
        margin-bottom: 0
    }
}

.team-area {
    position: relative;
    overflow: hidden
}

.team-item {
    padding: 0 20px 20px;
    margin-bottom: 25px;
    text-align: center;
    position: relative;
    transition: var(--transition);
    z-index: 1
}

.team-item::before {
    content: "";
    position: absolute;
    background: var(--color-white);
    border-radius: 30px;
    box-shadow: var(--box-shadow);
    width: 100%;
    height: 70%;
    left: 0;
    bottom: 0;
    z-index: -1
}

.team-item:hover {
    transform: translateY(-10px)
}

.team-img img {
    border-radius: 30px;
    padding: 6px;
    border: 3px solid var(--theme-color);
    box-shadow: var(--box-shadow)
}

.team-content {
    padding: 10px 0 5px
}

.team-bio h5 {
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-transform: capitalize
}

.team-bio span {
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-color);
    text-transform: capitalize
}

.team-bio h5 a:hover {
    color: var(--theme-color)
}

.team-social {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    transition: var(--transition)
}

.team-social a {
    color: var(--color-white);
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50px;
    margin: 0 4px;
    background: var(--theme-color);
    box-shadow: var(--box-shadow)
}

.team-social a:hover {
    background: var(--color-dark);
    color: var(--color-white)
}

.team-social h6 {
    margin-top: 28px;
    color: var(--color-white);
    font-size: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    position: relative;
    transform: rotate(-90deg)
}

.team-social h6::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 3px;
    background: var(--color-white);
    left: -50px;
    top: 7px
}

.team-single-img img {
    border-radius: 20px
}

.team-single-content {
    padding-left: 15px
}

.team-single-name {
    margin-bottom: 10px
}

.team-single-name p {
    font-weight: 500;
    color: var(--theme-color)
}

.team-single-info {
    margin-top: 20px
}

.team-single-info li {
    margin: 10px 0
}

.team-single-info-left {
    font-weight: 500
}

.team-single-info-right {
    margin-left: 10px
}

.team-single-info-left i {
    color: var(--theme-color);
    margin-right: 5px
}

.team-single-social a {
    height: 35px;
    width: 35px;
    line-height: 32px;
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    text-align: center;
    border-radius: 50px;
    margin-right: 5px
}

.team-single-social a:hover {
    background: var(--theme-color);
    color: var(--color-dark)
}

.team-single-overview {
    margin-top: 70px
}

.team-single-overview-img img {
    border-radius: 20px
}

@media (max-width: 991px) {
    .team-single-img {
        margin-bottom: 20px
    }

    .team-single-overview-img {
        margin-top: 20px
    }
}

.book-ride .booking-form {
    padding: 40px
}

.book-ride-head {
    text-align: center;
    padding-bottom: 30px;
    margin-bottom: 25px;
    border-bottom: 1px solid var(--border-info-color)
}

.book-ride .theme-btn {
    margin-top: 35px;
    text-align: center
}

.book-ride .form-check {
    margin-top: 25px
}

.book-ride .form-check-input {
    margin-top: 7px;
    box-shadow: none
}

.book-ride .form-check-input:focus {
    border-color: var(--theme-color)
}

.book-ride .form-check-label {
    color: var(--color-dark)
}

.rate-item {
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: var(--color-white);
    margin-bottom: 25px;
    border-radius: 20px;
    box-shadow: 0 10px 40px rgb(6 22 58/10%);
    transition: all .9s ease-in-out;
    z-index: 1
}

.rate-item:hover {
    transform: translateY(-10px)
}

.rate-header-content {
    margin: 30px 0 80px;
    text-align: center
}

.rate-header-content h4 {
    margin-bottom: 0;
    font-size: 22px;
    text-transform: uppercase
}

.rate-header-content p {
    color: var(--theme-color);
    font-weight: 500
}

.rate-content {
    background: var(--color-dark);
    padding: 30px;
    border-radius: 20px;
    position: relative;
    z-index: 1
}

.rate-content::before {
    content: "";
    position: absolute;
    background-image: url(/assets/img/shape/shape-4.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: .15;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1
}

.rate-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 50px;
    font-size: 56px;
    color: var(--color-white);
    background: var(--theme-color);
    position: relative;
    box-shadow: var(--box-shadow);
    margin: -80px auto 0
}

.rate-icon::before {
    content: "";
    position: absolute;
    left: -6px;
    right: -6px;
    top: -6px;
    bottom: -6px;
    border: 2px dashed var(--theme-color);
    border-radius: 50%;
    animation: rotate 9s linear infinite
}

@keyframes rotate {
    100% {
        transform: rotate(180deg)
    }
}

.rate-icon img {
    width: 55px
}

.rate-feature {
    margin-top: 30px
}

.rate-feature ul {
    margin-bottom: 25px
}

.rate-feature ul li {
    position: relative;
    margin: 10px 0;
    color: #fff
}

.rate-feature ul li i {
    color: var(--theme-color);
    margin-right: 5px
}

.rate-feature ul li span {
    float: right
}

.rate-item .theme-btn {
    width: 100%
}

.rate-item .theme-btn:hover {
    color: var(--color-dark)
}

.rate-item .theme-btn::before {
    background: var(--color-white)
}

.taxi-area {
    position: relative
}

.taxi-item {
    background: var(--color-white);
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 25px;
    box-shadow: var(--box-shadow)
}

.taxi-img {
    margin-bottom: 25px;
    padding: 20px;
    background: var(--theme-bg-light);
    border-radius: 15px
}

.taxi-head {
    text-align: center
}

.taxi-head h4 {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 700;
    text-transform: uppercase
}

.taxi-head span {
    font-weight: 500;
    color: var(--theme-color)
}

.taxi-feature {
    padding-top: 20px;
    margin: 20px 0 30px;
    border-top: 1px solid var(--border-info-color);
    position: relative
}

.taxi-feature::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 6px;
    background: var(--theme-color);
    border-radius: 50px;
    left: 50%;
    top: -4px;
    transform: translateX(-50%)
}

.taxi-feature ul li {
    padding: 4px 0
}

.taxi-feature ul li span {
    float: right
}

.taxi-feature ul li i {
    width: 30px;
    height: 30px;
    line-height: 28px;
    color: var(--color-white);
    border-radius: 50px;
    margin-right: 10px;
    text-align: center;
    background: var(--theme-color)
}

.taxi-content .theme-btn {
    width: 100%
}

.filter-btns {
    text-align: center;
    margin-bottom: 40px
}

.filter-btns li {
    display: inline-block;
    background: var(--color-dark);
    color: var(--color-white);
    border-radius: 50px;
    margin: 0 5px 8px 0;
    padding: 8px 25px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px
}

.filter-btns li.active {
    background: var(--theme-color)
}

.taxi-single-img {
    display: flex;
    align-items: center;
    height: 500px;
    border-radius: 20px;
    background: var(--theme-bg-light);
    position: relative
}

.taxi-single-img::before {
    content: "";
    position: absolute;
    border: 5px solid var(--theme-color);
    border-radius: 20px;
    width: 95%;
    height: 95%;
    left: -30px;
    bottom: -30px;
    z-index: -1
}

.taxi-single-img img {
    margin-left: -90px;
    max-width: fit-content
}

.taxi-single-details {
    padding-left: 70px
}

.taxi-single-top {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-info-color)
}

.taxi-single-tagline {
    color: var(--theme-color);
    font-weight: 500;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 3px
}

.taxi-single-meta {
    margin-top: 10px
}

.taxi-single-meta li {
    display: inline-block;
    margin-right: 12px
}

.taxi-single-meta li i {
    color: var(--theme-color);
    margin-right: 5px
}

.taxi-single-meta span {
    font-weight: 500
}

.taxi-key-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px
}

.taxi-key-item {
    display: flex;
    gap: 10px;
    margin-bottom: 10px
}

.taxi-key-icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    color: var(--color-white);
    background: var(--theme-color);
    border-radius: 50px;
    text-align: center;
    margin-top: 6px
}

.taxi-key-content {
    flex: 1
}

.taxi-key-item p {
    font-weight: 500
}

.taxi-single-btn {
    margin-top: 20px
}

.taxi-single-overview {
    margin-top: 80px;
    background: var(--theme-bg-light);
    padding: 30px;
    border-radius: 15px
}

.taxi-single-list li {
    margin: 5px 0
}

.taxi-single-list li i {
    color: var(--theme-color);
    margin-right: 5px
}

@media all and (max-width: 1199px) {
    .taxi-single-img img {
        margin-left: -10px;
        max-width: 100%
    }

    .taxi-single-details {
        padding-left: 20px
    }
}

@media all and (max-width: 991px) {
    .taxi-single-details {
        margin-top: 80px
    }
}

@media all and (max-width: 767px) {
    .taxi-single-img {
        margin-left: 25px;
        height: 300px
    }
}

.download-area {
    position: relative
}
/*OVDE*/
.download-wrapper {
    position: relative;
    background-image: url(/assets/img/shape/shape-7.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    padding: 40px;
    overflow: hidden;
    z-index: 1
}

.download-btn {
    display: flex;
    gap: 15px
}

.download-btn a {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px 20px;
    background: var(--theme-color);
    color: var(--color-white);
    border-radius: 50px;
    box-shadow: var(--box-shadow)
}

.download-btn a:first-child {
    background: var(--theme-color)
}

.download-btn a:last-child {
    background: var(--color-dark)
}

.download-btn a:hover {
    background: var(--color-dark)
}

.download-btn a:last-child:hover {
    background: var(--theme-color)
}

.download-btn a i {
    font-size: 40px
}

.download-btn-content {
    display: flex;
    flex-direction: column
}

.download-btn-content span {
    font-weight: 500;
    font-size: 14px
}

.download-img {
    position: absolute;
    right: 0;
    bottom: -30px;
    width: 55%
}

@media all and (max-width: 991px) {
    .download-img {
        position: relative;
        right: 35px;
        width: 150%
    }
}

@media all and (max-width: 767px) {
    .download-btn {
        flex-direction: column
    }

    .download-btn a {
        /*max-width: 180px*/
    }
}

.faq-area .accordion-item {
    border: none;
    margin-bottom: 30px;
    background: var(--color-white);
    border-radius: 12px !important;
    box-shadow: var(--box-shadow)
}

.faq-img img {
    border-radius: 15px
}

.faq-area .accordion-item span {
    width: 45px;
    height: 45px;
    margin-right: 15px
}

.faq-area .accordion-item i {
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50px;
    background: var(--theme-color);
    text-align: center;
    color: var(--color-white)
}

.faq-area .accordion-button:not(.collapsed) {
    color: var(--theme-color);
    background: 0 0;
    box-shadow: inset 0 -1px 0 rgb(0 0 0/13%)
}

.accordion-button {
    border-radius: 0 !important;
    background: 0 0;
    font-weight: 700;
    font-size: 20px;
    color: var(--color-dark);
    box-shadow: none !important
}

.accordion-button:not(.collapsed) {
    border-bottom: 1px solid var(--theme-color)
}

.accordion-button:not(.collapsed)::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nIzIxMjUyOSc+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNMS42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAgMSAuNzA4LjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOCAwbC02LTZhLjUuNSAwIDAgMSAwLS43MDh6Jy8+PC9zdmc+)
}

@media all and (max-width: 991px) {
    .faq-right {
        margin-bottom: 50px
    }

    .accordion-button {
        font-size: 16px
    }
}

.site-breadcrumb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    z-index: 1
}

.site-breadcrumb::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: var(--hero-overlay-color);
    opacity: .75;
    z-index: -1
}

.site-breadcrumb .breadcrumb-title {
    font-size: 40px;
    color: var(--color-white);
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: capitalize
}

.site-breadcrumb .breadcrumb-menu {
    position: relative;
    z-index: 1
}

.site-breadcrumb .breadcrumb-menu li {
    position: relative;
    display: inline-block;
    margin-left: 25px;
    color: var(--color-white);
    font-weight: 500;
    text-transform: capitalize
}

.site-breadcrumb .breadcrumb-menu li a {
    color: var(--color-white);
    transition: all .5s ease-in-out
}

.site-breadcrumb .breadcrumb-menu li::before {
    position: absolute;
    content: '\f101';
    font-family: 'font awesome 6 pro';
    right: -21px;
    top: 1px;
    text-align: center;
    font-size: 16px;
    color: var(--color-white)
}

.site-breadcrumb .breadcrumb-menu li:first-child {
    margin-left: 0
}

.site-breadcrumb .breadcrumb-menu li:last-child:before {
    display: none
}

.site-breadcrumb .breadcrumb-menu li a:hover {
    color: var(--theme-color)
}

.site-breadcrumb .breadcrumb-menu li.active {
    /*color: var(--theme-color)*/
    color: #ffffff;
}

@media (max-width: 991px) {
    .site-breadcrumb {
        padding-top: 120px
    }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px
}

.pagination .page-link {
    border: none;
    background: var(--color-dark);
    color: var(--color-white);
    margin: 0 10px;
    border-radius: 50px !important;
    width: 40px;
    height: 40px;
    line-height: 28px;
    text-align: center;
    transition: var(--transition)
}

.pagination .page-link:hover, .pagination .page-item.active .page-link {
    background: var(--theme-color);
    color: var(--color-white)
}

body.rtl-mode {
    direction: rtl
}

body.rtl-mode .item-area {
    direction: ltr
}

body.rtl-mode .price-wrapper {
    direction: rtl
}

@media (min-width: 992px) {
    body.rtl-mode .navbar-brand {
        margin-left: 3rem
    }

    body.rtl-mode .navbar .nav-item .nav-link {
        margin-left: 3rem;
        margin-right: 0
    }
}

.login-form {
    padding: 40px;
    background: var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    position: relative
}

.login-form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50px;
    right: 50px;
    background: var(--theme-color);
    height: 12px;
    border-radius: 0 0 50px 50px
}

.login-form::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50px;
    right: 50px;
    background: var(--theme-color);
    height: 12px;
    border-radius: 50px 50px 0 0
}

.login-form .login-header {
    text-align: center;
    margin-bottom: 50px
}

.login-form .login-header img {
    width: 200px;
    margin-bottom: 10px
}

.login-form .login-header h3 {
    color: var(--theme-color);
    margin-bottom: 5px;
    font-weight: 800
}

.login-form .login-header p {
    font-size: 20px
}

.login-form .login-footer {
    margin-top: 25px
}

.login-form .login-footer p {
    text-align: center
}

.login-form .login-footer a {
    color: var(--theme-color);
    transition: .5s
}

.login-form .login-footer a:hover {
    color: var(--color-dark)
}

.login-form .form-group {
    margin-bottom: 20px
}

.login-form label {
    color: var(--color-dark);
    margin-bottom: 5px
}

.login-form .form-group .form-control {
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    padding: 12px 18px;
    box-shadow: none;
    transition: .5s
}

.login-form .form-group .form-control:focus {
    border-color: var(--theme-color)
}

.login-form .form-check-input {
    box-shadow: none;
    border-radius: 2
}

.login-form .forgot-pass {
    color: var(--theme-color);
    transition: .5s
}

.login-form .forgot-pass:hover {
    color: var(--color-dark)
}

.login-form .theme-btn {
    width: 100%
}

.login-form .theme-btn::before {
    width: 420px;
    height: 420px
}

.login-form .theme-btn i {
    margin-right: 5px
}

.login-form .form-check-input {
    margin-top: 6.3px
}

.login-form .form-check-label a {
    color: var(--theme-color);
    transition: .5s
}

.login-form .form-check-label a:hover {
    color: var(--theme-color)
}

.form-check-input:checked {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.social-login {
    border-top: 1px solid #f1f1f1;
    margin-top: 15px
}

.social-login p {
    margin: 10px 0
}

.social-login-list {
    text-align: center
}

.social-login-list a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: var(--theme-color);
    border-radius: 50px;
    margin: 5px
}

.social-login-list a i {
    color: var(--color-white)
}

.social-login-list a:hover {
    background: var(--color-dark)
}

@media only screen and (max-width: 991px) {
    .login-form {
        padding: 40px 20px
    }
}

.nav-right-account img {
    width: 50px;
    border-radius: 50%;
    padding: 3px;
    border: 3px solid var(--theme-color);
    cursor: pointer
}

.nav-right-account .dropdown-menu {
    border: none;
    min-width: 200px;
    border-radius: 10px;
    box-shadow: var(--box-shadow)
}

.nav-right-account .dropdown-menu li a i {
    margin-right: 5px
}

.nav-right-account .dropdown-menu .dropdown-item {
    padding: 8px 20px;
    font-weight: 500
}

.nav-right-account .dropdown-menu .dropdown-item:hover {
    background: 0 0;
    color: var(--theme-color);
    padding-left: 25px
}

.mobile-menu-right {
    gap: 20px
}

.mobile-menu-right .nav-right-account img {
    width: 35px;
    padding: 0;
    border: none
}

.user-profile-sidebar {
    background: var(--color-white);
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 20px;
    box-shadow: var(--box-shadow)
}

.user-profile-sidebar-top {
    text-align: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border-info-color)
}

.user-profile-img {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto 15px;
    border-radius: 50px
}

.user-profile-img img {
    border-radius: 50%
}

.profile-img-file {
    display: none
}

.user-profile-img button {
    position: absolute;
    border-radius: 50px;
    background: var(--theme-color);
    color: var(--color-white);
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    border: none;
    right: 0;
    bottom: 0
}

.user-profile-sidebar-list li {
    margin: 5px 0
}

.user-profile-sidebar-list li a {
    color: var(--color-dark);
    padding: 6px 6px 6px 15px;
    display: block;
    font-weight: 500;
    transition: var(--transition)
}

.user-profile-sidebar-list li a:hover {
    padding-left: 20px
}

.user-profile-sidebar-list li a i {
    margin-right: 5px;
    color: var(--theme-color)
}

.user-profile-sidebar-list .active i {
    color: var(--color-white)
}

.user-profile-sidebar-list .active {
    background: var(--theme-color);
    color: var(--color-white);
    border-radius: 8px
}

.user-profile-card {
    background: var(--color-white);
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 25px;
    box-shadow: var(--box-shadow)
}

.user-profile-card-title {
    color: var(--color-dark);
    font-size: 20px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-info-color)
}

.user-profile-card .text-success {
    color: var(--theme-color) !important
}

.user-profile-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--border-info-color)
}

.user-profile-card-header .user-profile-card-title {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0
}

.user-profile-card-header-right {
    display: flex;
    align-items: center;
    gap: 20px
}

.user-profile-search .form-group {
    position: relative
}

.user-profile-search .form-control {
    padding: 10px 12px 10px 35px;
    box-shadow: none;
    border-radius: 10px
}

.user-profile-search .form-control:focus {
    border-color: var(--theme-color)
}

.user-profile-search i {
    position: absolute;
    left: 12px;
    top: 15px
}

.user-profile-card-header .theme-btn {
    padding: 8px 15px
}

.profile-info-list li {
    margin: 12px 0;
    font-weight: 500
}

.profile-info-list li span {
    float: right;
    font-weight: 400
}

.user-profile-form .form-group {
    margin-bottom: 20px
}

.user-profile-form .form-group label {
    color: var(--color-dark);
    margin-bottom: 5px
}

.user-profile-form .form-control {
    padding: 14px 20px;
    border-radius: 10px;
    box-shadow: none;
    border-color: #e8e8e8
}

.user-profile-form .nice-select {
    border-color: #e8e8e8
}

.user-profile-form .nice-select:focus, .user-profile-form .form-control:focus {
    border-color: var(--theme-color)
}

.user-profile-form .theme-btn {
    width: unset
}

.user-profile .badge-success {
    background: #dcfce7;
    color: #22c79c
}

.user-profile .badge-info {
    background: #f3e8ff;
    color: #a855f7
}

.user-profile .badge-primary {
    background: #dbf7fd;
    color: #0dcaf0
}

.user-profile .badge-danger {
    background: #fee2e2;
    color: #ef4444
}

.dashboard-widget {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0 0 40px 5px rgb(0 0 0/3%)
}

.dashboard-widget-info span {
    font-weight: 500
}

.dashboard-widget-info h1 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 5px
}

.dashboard-widget-icon {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    border-radius: 50px
}

.dashboard-widget-color-1 {
    background: rgba(98, 89, 202, .15);
    color: #6259ca
}

.dashboard-widget-color-1 h1 {
    color: #6259ca
}

.dashboard-widget-color-1 .dashboard-widget-icon {
    background: #6259ca;
    color: #fff
}

.dashboard-widget-color-2 {
    background: rgba(14, 198, 198, .15);
    color: #29bf6c
}

.dashboard-widget-color-2 h1 {
    color: #29bf6c
}

.dashboard-widget-color-2 .dashboard-widget-icon {
    background: #29bf6c;
    color: #fff
}

.dashboard-widget-color-3 {
    background: rgba(255, 179, 0, .15);
    color: #ffb300
}

.dashboard-widget-color-3 h1 {
    color: #ffb300
}

.dashboard-widget-color-3 .dashboard-widget-icon {
    background: #ffb300;
    color: #fff
}

.table-list-info a {
    display: flex;
    align-items: center;
    gap: 10px
}

.table-list-info h6 {
    transition: all .5s ease-in-out
}

.table-list-info p {
    color: var(--color-dark)
}

.table-list-info:hover h6 {
    color: var(--theme-color)
}

.table-list-info span {
    color: var(--color-dark)
}

.table-list-info img {
    width: 90px;
    border-radius: 8px
}

.user-profile-card .table > :not(caption) > * > * {
    vertical-align: middle;
    padding: .8rem
}

.profile-setting .form-check {
    margin-bottom: 10px
}

.profile-setting .form-check-input {
    margin-top: 6.5px;
    box-shadow: none
}

@media all and (max-width: 767px) {
    .table-listing-info a {
        display: block
    }

    .table-listing-info h6 {
        margin-top: 10px
    }

    .user-profile-card-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px
    }

    .user-profile-card-header-right {
        flex-direction: column-reverse;
        align-items: flex-start
    }
}

.coming-soon {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    position: relative
}

.coming-soon .container {
    position: relative
}

.coming-soon:before {
    position: absolute;
    content: '';
    background: rgba(0, 0, 0, .7);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.coming-soon .newsletter-form {
    position: relative;
    margin-top: 30px
}

.coming-soon .newsletter-form .input-newsletter {
    height: 55px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 50px;
    font-size: 14px;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 25px
}

.coming-soon .newsletter-form button {
    position: absolute;
    right: 4px;
    top: 4px;
    bottom: 4px;
    height: auto;
    border: none;
    border-radius: 50px;
    background: var(--theme-color);
    display: inline-block;
    color: var(--color-white);
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 500;
    transition: .5s
}

.coming-soon .newsletter-form button:hover {
    background-color: var(--theme-color);
    color: var(--color-white)
}

.coming-social {
    margin-top: 30px;
    text-align: center
}

.coming-social a {
    color: var(--color-white);
    margin: 5px;
    width: 40px;
    height: 40px;
    line-height: 36px;
    text-align: center;
    border-radius: 50px;
    border: 2px solid var(--color-white);
    display: inline-block;
    transition: .5s
}

.coming-social a:hover {
    background: var(--theme-color);
    border-color: var(--theme-color)
}

.countdown-wrap {
    margin-top: 30px
}

.countdown-single {
    background: var(--theme-color);
    padding: 20px;
    margin: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    box-shadow: var(--box-shadow)
}

.countdown-single h2 {
    color: var(--color-white)
}

.countdown-single h5 {
    color: var(--color-white)
}

.error-wrapper {
    text-align: center
}

.error-wrapper h1 {
    font-size: 250px;
    letter-spacing: 5px;
    font-weight: 700;
    color: var(--theme-color)
}

.error-wrapper h1 span {
    color: var(--color-dark)
}

.error-wrapper h2 {
    margin-top: 30px;
    margin-bottom: 10px
}

.error-wrapper img {
    width: 100%
}

.error-wrapper .theme-btn {
    margin-top: 30px
}

@media all and (max-width: 767px) {
    .error-wrapper h1 {
        font-size: 160px
    }
}

.partner {
    position: relative
}

.partner-slider {
    padding-top: 50px;
    border-top: 1px solid var(--border-info-color)
}

.partner-img img {
    -webkit-filter: grayscale(100%) invert(.6);
    filter: grayscale(100%) invert(.6);
    cursor: pointer
}

.partner-img img:hover {
    filter: unset
}

.terms-content:not(:last-child) {
    margin-bottom: 54px
}

.terms-content:first-child {
    margin-top: -3px
}

.terms-content .terms-list {
    margin-top: 37px
}

.terms-content h3 {
    margin-bottom: 23px;
    position: relative
}

.terms-content p:not(:last-child) {
    margin-bottom: 26px
}

.terms-list li:not(:last-child) {
    margin-bottom: 16px
}

.footer-area {
    background: var(--color-dark);
    position: relative;
    z-index: 1
}

.footer-area::before {
    content: "";
    position: absolute;
    background-image: url(/assets/img/shape/shape-2.png);
    background-repeat: repeat-x;
    width: 100%;
    height: 50px;
    left: 0;
    top: 0;
    z-index: -1
}

.footer-widget-box {
    margin-bottom: 20px
}

.footer-widget {
    position: relative;
    z-index: 1
}

.footer-logo img {
    width: 210px;
    margin-bottom: 20px
}

.copyright {
    position: relative;
    padding: 20px 0;
    background: var(--footer-bg2);
    border-bottom: 5px solid var(--theme-color);
    z-index: 1
}

.copyright::before {
    content: "";
    position: absolute;
    right: 0;
    top: -10px;
    bottom: -1px;
    background: var(--theme-color);
    width: 50%;
    clip-path: polygon(8% 0%, 100% 0, 100% 100%, 0% 100%);
    z-index: -1
}

.copyright .footer-menu {
    margin: 0;
    padding: 0;
    text-align: right
}

.copyright .footer-menu li {
    display: inline-block;
    margin-left: 25px;
    font-size: 16px
}

.copyright .footer-menu li a {
    color: var(--footer-text-color);
    transition: var(--transition)
}

.copyright .footer-menu li a:hover {
    color: var(--theme-color)
}

.copyright .copyright-text {
    color: var(--footer-text-color);
    margin-bottom: 0;
    font-size: 16px
}

.copyright .copyright-text a {
    color: var(--theme-color);
    font-weight: 500
}

.footer-widget-title {
    color: var(--color-white);
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 30px;
    font-size: 21px;
    font-weight: 600;
    z-index: 1
}

.footer-widget-title::before {
    position: absolute;
    content: '';
    z-index: -1;
    width: 90px;
    height: 2px;
    background: rgba(255, 255, 255, .2);
    bottom: 0;
    left: 0
}

.footer-widget-title::after {
    position: absolute;
    content: '';
    z-index: -1;
    width: 30px;
    height: 2px;
    background-color: var(--theme-color);
    bottom: 0;
    left: 18px
}

.footer-list {
    display: flex;
    flex-direction: column;
    gap: .65rem
}

.footer-list li a {
    color: var(--color-white);
    transition: var(--transition)
}

.footer-list li a i {
    margin-right: 5px;
    color: var(--theme-color)
}

.footer-list li a:hover {
    padding-left: 10px;
    color: var(--theme-color)
}

.footer-widget-box p {
    color: var(--color-white);
    padding-right: 18px;
    margin-bottom: 20px
}

.footer-social {
    display: flex;
    gap: 15px;
    justify-content: end
}
.footer-social a {
   color:#fff;
}
.footer-social a:hover {
   color:#000;
}

.footer-social li a i {
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 50px;
    background: var(--color-dark);
    color: var(--theme-color);
    transition: var(--transition)
}

.footer-social li a i:hover {
    background: var(--color-white);
    color: var(--theme-color)
}

.footer-contact li {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    color: var(--footer-text-color);
    font-size: 16px;
    margin-bottom: 10px
}

.footer-contact li a {
    color: var(--footer-text-color);
    -webkit-transition: .3s;
    transition: .3s
}

.footer-contact li i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    margin-right: 15px;
    border-radius: 50px;
    background: var(--theme-color);
    text-align: center;
    -webkit-transition: .3s;
    transition: .3s;
    color: var(--color-white)
}

.subscribe-form .form-control {
    padding: 16px 20px;
    border-radius: 12px;
    box-shadow: none;
    border: none
}

.subscribe-form .theme-btn {
    margin-top: 20px;
    width: 100%;
    padding: 14px
}

.subscribe-form .theme-btn:hover {
    color: var(--theme-color)
}

.subscribe-form .theme-btn::before {
    background: var(--color-white)
}

@media all and (max-width: 1199px) {
    .footer-widget-box {
        margin-bottom: 50px
    }
}

@media all and (max-width: 991px) {
    .footer-widget-wrapper {
        padding-bottom: 0
    }

    .copyright .footer-menu {
        float: left;
        margin-top: 20px;
        text-align: left
    }

    .copyright .footer-menu li {
        margin-left: 0;
        margin-right: 15px
    }
}

@media all and (max-width: 767px) {
    .copyright .copyright-text {
        text-align: center;
    }
    .footer-widget-wrapper {
        padding-bottom: 0
    }
    .footer-social {
        justify-content: flex-start;
        margin-top: 20px
    }
    .copyright::before {
        clip-path: polygon(30% 1%, 100% 0, 100% 100%, 0% 100%)
    }

    .copyright .copyright-text a {
        color: var(--theme-color)
    }
}

.home-2 .header-top {
    background: var(--theme-color)
}

.home-2 .header-top::before {
    left: 5%;
    right: 5%;
    width: unset;
    border-radius: 0 0 20px 20px;
    background: var(--color-dark);
    border: none
}

.home-2 .header-top::after {
    display: none
}

.home-2 .about-area .about-experience {
    left: -50px;
    top: 50px;
    background: var(--color-white);
    color: var(--color-dark)
}

.home-2 .service-item {
    background: var(--color-white);
    margin-top: 45px;
    border-radius: 15px
}

.home-2 .service-item::before {
    display: none
}

.home-2 .download-area {
    margin-bottom: -150px;
    position: relative;
    z-index: 2
}

.home-2 .footer-widget-wrapper {
    padding-top: 250px
}

.home-2 .hero-single .hero-title span {
    -webkit-text-stroke: 2px var(--theme-color);
    -webkit-text-fill-color: transparent
}

.home-3 .header-top {
    background: var(--theme-color)
}

.home-3 .header-top::before {
    left: 5%;
    right: 5%;
    width: unset;
    border-radius: 0;
    clip-path: polygon(4% 0%, 100% 0%, 96% 100%, 0% 100%);
    background: var(--color-dark);
    border: none
}

.home-3 .header-top::after {
    display: none
}

.home-3 .navbar {
    background: 0 0;
    box-shadow: none
}

.home-3 .navbar.fixed-top {
    background: #fff;
    box-shadow: var(--box-shadow)
}

.home-3 .main {
    margin-top: -6rem
}

.home-3 .hero-single {
    padding-top: 180px;
    background: rgba(255, 179, 0, .05)
}

.home-3 .hero-shape img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    opacity: .1
}

.home-3 .hero-single::before {
    display: none
}

.home-3 .hero-single::after {
    content: "";
    position: absolute;
    width: 420px;
    height: 100%;
    background: var(--theme-color);
    opacity: .1;
    right: 0;
    top: 0;
    z-index: -2
}

.home-3 .hero-single .hero-title, .home-3 .hero-single p {
    color: var(--color-dark)
}

.home-3 .hero-single .hero-title span {
    -webkit-text-stroke: 2px var(--theme-color);
    -webkit-text-fill-color: transparent
}

.home-3 .hero-single .hero-img {
    position: relative;
    z-index: 1
}

.home-3 .hero-single .hero-img img {
    border-radius: 50px
}

.home-3 .hero-single .hero-img::before {
    content: "";
    position: absolute;
    border: 5px solid var(--theme-color);
    border-radius: 50px;
    width: 80%;
    height: 80%;
    right: -30px;
    top: -30px;
    z-index: -1
}

.home-3 .footer-area::before {
    display: none
}

.home-3 .footer-shape img {
    position: absolute;
    width: 60%;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    opacity: .1;
    z-index: -1
}

@media all and (max-width: 991px) {
    .hero-single {
        padding-top: 100px
    }
    .home-3 .hero-single {
        padding-top: 220px
    }

    .home-3 .hero-single .hero-img {
        margin-top: 80px
    }
}

.filter-white{
    filter: invert(100%) sepia(0%) saturate(25%) hue-rotate(70deg) brightness(108%) contrast(108%);
}
a{
    text-decoration: none !important;
}
.dpicker.ui-datepicker {
    width: 18em;
    margin-top:14px;
    display: none;
    background: #fff;
    position:relative;
    font: 14px/1.55  "Roboto", Arial, Helvetica, sans-serif;
    -webkit-box-shadow: 0 0 4px rgba(0,0,0,.3);
    -moz-box-shadow: 0 0 4px rgba(0,0,0,.3);
    -o-box-shadow: 0 0 4px rgba(0,0,0,.3);
    box-shadow: 0 0 4px rgba(0,0,0,.3);
    border:5px solid #176ac4;
    z-index:9999!important;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -o-border-radius:3px;
    border-radius:3px;
    text-align: center;
    color: #666;

}


.dpicker.ui-datepicker:before{
    content:"";
    bottom:auto;
    border: solid;
    border-width:16px 16px 16px;
    border-color:transparent transparent #d9001e transparent;
    border-color:rgba(255,255,255,0) rgba(255,255,255,0) #d9001e rgba(255,255,255,0);
    position:absolute;
    margin-left:-10px;
    display:block;
    top:-32px;
    left:10%;
    height:0;
    width:0;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: 1px solid #ececec;
    background: #f6f6f6;
    font-weight: normal;
    color: #454545;
}


.dpicker.ui-datepicker a { text-align:center; }
.dpicker.ui-datepicker .ui-state-disabled span{ color:#D9DDE5;}
.dpicker.ui-timepicker-div .ui-widget-header,
.dpicker.ui-datepicker .ui-datepicker-header {
    position: relative;
    background:#d9001e;
    line-height: 27px;
    font-size: 15px;
    padding: 3px;
}

.dpicker.ui-datepicker .ui-datepicker-prev,
.dpicker.ui-datepicker .ui-datepicker-next {
    width: 34px;
    height: 34px;
    display: block;
    font-size: 14px;
    position: absolute;
    text-decoration: none;
    cursor: pointer;
    color:#fff;
    top:19.5%;
}

.dpicker.ui-datepicker .ui-datepicker-prev { left: 2px;  }
.dpicker.ui-datepicker .ui-datepicker-next { right: 2px; }
.dpicker.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
    color:#fff;
}

.dpicker.ui-datepicker .ui-datepicker-title select { font-size: 1em; margin: 1px 0; }
.dpicker.ui-datepicker select.ui-datepicker-month-year { width: 100%; }
.dpicker.ui-datepicker select.ui-datepicker-month,
.dpicker.ui-datepicker select.ui-datepicker-year { width: 49%; }
.dpicker.ui-datepicker table {
    width: 100%;
    font-size: .9em;
    margin: 0 0 .4em;
    border-collapse: collapse;
}

.dpicker.ui-datepicker th {
    padding: .5em .3em;
    text-align: center;
    font-weight: bold;
    border: 0;
}

.dpicker.ui-datepicker td { border: 0; padding:0;  }
.dpicker.ui-datepicker td span,
.dpicker.ui-datepicker td a {
    padding: .25em;
    display: block;
    text-align: center;
    text-decoration: none;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    -o-border-radius:0;
    border-radius:0;
}

.dpicker.ui-datepicker td span:hover,
.dpicker.ui-datepicker td a:hover {  background:#D7DCDE; }
.dpicker.ui-datepicker .ui-state-disabled span:hover{ background:none; }

/* @changes to the datepicker and month picker widget in v.4
------------------------------------------------------------- */
.dpicker.ui-datepicker-today a,
.dpicker.ui-datepicker-today a:hover,
.dpicker.ui-datepicker .ui-state-highlight{
    -webkit-border-radius:15px;
    -moz-border-radius:15px;
    -o-border-radius:15px;
    border-radius:15px;
    font-weight: 700;

    background: #d9001e33 !important;
    color: #000;

}

.dpicker.ui-monthpicker .ui-datepicker-today a,
.dpicker.ui-monthpicker .ui-datepicker-today a:hover,
.dpicker.ui-datepicker .ui-datepicker-current-day a{
    font-weight: 700;
    background: #d9001e !important;
    -webkit-border-radius:0;
    -moz-border-radius:0;
    -o-border-radius:0;
    border-radius:0;
    color:#fff !important;
}

/* @multiple calendars || not responsive use carefully
--------------------------------------------------------------- */
.dpicker.cal-widget .ui-datepicker { width: 100%; margin-top:0; }
.dpicker.cal-widget .ui-datepicker:before{ display:none; }
.dpicker.ui-datepicker.ui-datepicker-multi { width: auto; }
.dpicker.ui-datepicker-multi .ui-datepicker-group { float: left; }
.dpicker.ui-datepicker-multi .ui-datepicker-group table { width: 95%; margin: 0 auto .4em; }
.dpicker.ui-datepicker-multi-2 .ui-datepicker-group { width: 50%; }
.dpicker.ui-datepicker-multi-3 .ui-datepicker-group { width: 33.333%; }
.dpicker.ui-datepicker-multi-4 .ui-datepicker-group { width: 25%; }
.dpicker.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.dpicker.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width: 0; }
.dpicker.ui-datepicker-multi .ui-datepicker-buttonpane { clear: left; }
.dpicker.ui-datepicker-row-break { clear: both; width: 100%; font-size: 0; }


/* @ ui buttons
---------------------------------------------------------------- */
.dpicker.ui-datepicker-buttonpane{ border-top:1px solid #D9DDE5; padding:10px;  }
.dpicker.ui-datepicker-buttonpane button {
    padding: 8px 12px;
    margin-right: .2em;
    position: relative;
    line-height: normal;
    display: inline-block;
    -webkit-user-drag: none;
    text-shadow: 0 1px rgba(255, 255, 255, 0.2);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    vertical-align: middle;
    background: #bdc3c7;
    text-align: center;
    overflow: visible;
    cursor: pointer;
    color: #243140;
    border:0;
}

/* @ ui buttons :hover, :active states
---------------------------------------------------------------- */
.dpicker.ui-datepicker-buttonpane button:hover { color: #243140; background: #cacfd2; }
.dpicker.ui-datepicker-buttonpane button:active{ color: #1d2938; background: #a1a6a9; }
.dpicker.ui-monthpicker .ui-datepicker-header{ margin-bottom:3px; }





.dpicker.ui-datepicker-week-col {
    background: #e8faff;
    border-right: 1px solid #176ac4 !important;
}
.dpicker.ui-datepicker td span, .dpicker.ui-datepicker td a {
    padding: 0.45em !important;

}

.dpicker.ui-datepicker table {
    margin: 0 0 0em !important;
}

.ui-datepicker-week-col {
    background: rgba(217, 0, 30, 0.09);
    border-right: 1px solid #d0d0d0 !important;
}
.ui-datepicker-week-end {
    background: #fee;
    color: #ff0000;
}
.ui-datepicker-week-end a{
    color: #ff0000 !important;
}

/*
.ui-datepicker { border:5px solid #3498db; }
.ui-datepicker:before{
    border-color:transparent transparent #3498db transparent;
    border-color:rgba(255,255,255,0) rgba(255,255,255,0) #3498db rgba(255,255,255,0);
}
.ui-timepicker-div .ui-widget-header,
.ui-datepicker .ui-datepicker-header { background:#3498db; }
.ui-monthpicker .ui-datepicker-today a,
.ui-monthpicker .ui-datepicker-today a:hover,
.ui-datepicker .ui-datepicker-current-day a{
    background:#3498db!important;
}*/



/*TIMEPICKER*/

.tpicker.ui-widget {
    font-family: Arial,Helvetica,sans-serif/*{ffDefault}*/;
    font-size: 1em/*{fsDefault}*/;
}
.tpicker.ui-widget .ui-widget {
    font-size: 1em;
}
.tpicker.ui-widget input,
.tpicker.ui-widget select,
.tpicker.ui-widget textarea,
.tpicker.ui-widget button {
    font-family: Arial,Helvetica,sans-serif/*{ffDefault}*/;
    font-size: 1em;
}
.tpicker .ui-widget.ui-widget-content {
    border: 1px solid #c5c5c5/*{borderColorDefault}*/;
}
.tpicker .ui-widget-content {
    /*border: 1px solid #dddddd!*{borderColorContent}*!;*/
    background: #ffffff/*{bgColorContent}*/ /*{bgImgUrlContent}*/ /*{bgContentXPos}*/ /*{bgContentYPos}*/ /*{bgContentRepeat}*/;
    color: #333333/*{fcContent}*/;
}
.tpicker. ui-widget-content a {
    color: #333333/*{fcContent}*/;
}
.tpicker .ui-widget-header {
    /*border: 1px solid #dddddd!*{borderColorHeader}*!;*/
    /*background: #e9e9e9!*{bgColorHeader}*! !*{bgImgUrlHeader}*! !*{bgHeaderXPos}*! !*{bgHeaderYPos}*! !*{bgHeaderRepeat}*!;*/
    color: #FFFFFF/*{fcHeader}*/;
    font-weight: bold;
}
.tpicker .ui-widget-header a {
    color: #333333/*{fcHeader}*/;
}

/* Interaction states
----------------------------------*/
.tpicker .ui-state-default,
.tpicker .ui-widget-content .ui-state-default,
.tpicker .ui-widget-header .ui-state-default,
.tpicker .ui-button,

    /* We use html here because we need a greater specificity to make sure disabled
    works properly when clicked or hovered */
html .tpicker .ui-button.ui-state-disabled:hover,
html .tpicker .ui-button.ui-state-disabled:active {
    border: 1px solid #c5c5c5/*{borderColorDefault}*/;
    background: #f6f6f6/*{bgColorDefault}*/ /*{bgImgUrlDefault}*/ /*{bgDefaultXPos}*/ /*{bgDefaultYPos}*/ /*{bgDefaultRepeat}*/;
    font-weight: normal/*{fwDefault}*/;
    color: #454545/*{fcDefault}*/;
}
.tpicker .ui-state-default a,
.tpicker .ui-state-default a:link,
.tpicker .ui-state-default a:visited,
.tpicker a.ui-button,
.tpicker a:link.ui-button,
.tpicker a:visited.ui-button,
.tpicker .ui-button {
    color: #454545/*{fcDefault}*/;
    text-decoration: none;
}
.tpicker .ui-state-hover,
.tpicker .ui-widget-content .ui-state-hover,
.tpicker .ui-widget-header .ui-state-hover,
.tpicker .ui-state-focus,
.tpicker .ui-widget-content .ui-state-focus,
.tpicker .ui-widget-header .ui-state-focus,
.tpicker .ui-button:hover,
.tpicker .ui-button:focus {
    border: 1px solid #cccccc/*{borderColorHover}*/;
    background: #176ac4/*{bgColorHover}*/ /*{bgImgUrlHover}*/ /*{bgHoverXPos}*/ /*{bgHoverYPos}*/ /*{bgHoverRepeat}*/;
    font-weight: normal/*{fwDefault}*/;
    color: #ffffff/*{fcHover}*/;
}
.tpicker .ui-state-hover a,
.tpicker .ui-state-hover a:hover,
.tpicker .ui-state-hover a:link,
.tpicker .ui-state-hover a:visited,
.tpicker .ui-state-focus a,
.tpicker .ui-state-focus a:hover,
.tpicker .ui-state-focus a:link,
.tpicker .ui-state-focus a:visited,
.tpicker a.ui-button:hover,
.tpicker a.ui-button:focus {
    color: #2b2b2b/*{fcHover}*/;
    text-decoration: none;
}

.tpicker .ui-visual-focus {
    box-shadow: 0 0 3px 1px rgb(94, 158, 214);
}
.tpicker .ui-state-active,
.tpicker .ui-widget-content .ui-state-active,
.tpicker .ui-widget-header .ui-state-active,
.tpicker a.ui-button:active,
.tpicker .ui-button:active,
.tpicker .ui-button.ui-state-active:hover {
    border: 1px solid #176ac4/*{borderColorActive}*/;
    background: #176ac4/*{bgColorActive}*/ /*{bgImgUrlActive}*/ /*{bgActiveXPos}*/ /*{bgActiveYPos}*/ /*{bgActiveRepeat}*/;
    font-weight: normal/*{fwDefault}*/;
    color: #ffffff/*{fcActive}*/;
}
.tpicker .ui-icon-background,
.tpicker .ui-state-active .ui-icon-background {
    border: #003eff/*{borderColorActive}*/;
    background-color: #ffffff/*{fcActive}*/;
}
.tpicker .ui-state-active a,
.tpicker .ui-state-active a:link,
.tpicker .ui-state-active a:visited {
    color: #ffffff/*{fcActive}*/;
    text-decoration: none;
}

/* Interaction Cues
----------------------------------*/
.tpicker .ui-state-highlight,
.tpicker .ui-widget-content .ui-state-highlight,
.tpicker .ui-widget-header .ui-state-highlight {
    border: 1px solid #dad55e/*{borderColorHighlight}*/;
    background: #fffa90/*{bgColorHighlight}*/ /*{bgImgUrlHighlight}*/ /*{bgHighlightXPos}*/ /*{bgHighlightYPos}*/ /*{bgHighlightRepeat}*/;
    color: #777620/*{fcHighlight}*/;
}
.tpicker .ui-state-checked {
    border: 1px solid #dad55e/*{borderColorHighlight}*/;
    background: #fffa90/*{bgColorHighlight}*/;
}
.tpicker .ui-state-highlight a,
.tpicker .ui-widget-content .ui-state-highlight a,
.tpicker .ui-widget-header .ui-state-highlight a {
    color: #777620/*{fcHighlight}*/;
}
.tpicker .ui-state-error,
.tpicker .ui-widget-content .ui-state-error,
.tpicker .ui-widget-header .ui-state-error {
    border: 1px solid #f1a899/*{borderColorError}*/;
    background: #fddfdf/*{bgColorError}*/ /*{bgImgUrlError}*/ /*{bgErrorXPos}*/ /*{bgErrorYPos}*/ /*{bgErrorRepeat}*/;
    color: #5f3f3f/*{fcError}*/;
}
.tpicker .ui-state-error a,
.tpicker .ui-widget-content .ui-state-error a,
.tpicker .ui-widget-header .ui-state-error a {
    color: #5f3f3f/*{fcError}*/;
}
.tpicker .ui-state-error-text,
.tpicker .ui-widget-content .ui-state-error-text,
.tpicker .ui-widget-header .ui-state-error-text {
    color: #5f3f3f/*{fcError}*/;
}
.tpicker .ui-priority-primary,
.tpicker .ui-widget-content .ui-priority-primary,
.tpicker .ui-widget-header .ui-priority-primary {
    font-weight: bold;
}
.tpicker .ui-priority-secondary,
.tpicker .ui-widget-content .ui-priority-secondary,
.tpicker .ui-widget-header .ui-priority-secondary {
    opacity: .7;
    filter:Alpha(Opacity=70); /* support: IE8 */
    font-weight: normal;
}
.tpicker .ui-state-disabled,
.tpicker .ui-widget-content .ui-state-disabled,
.tpicker .ui-widget-header .ui-state-disabled {
    opacity: .35;
    filter:Alpha(Opacity=35); /* support: IE8 */
    background-image: none;
}
.tpicker .ui-state-disabled .ui-icon {
    filter:Alpha(Opacity=35); /* support: IE8 - See #6059 */
}

/* Icons
----------------------------------*/

/* states and images */
.tpicker .ui-icon {
    width: 16px;
    height: 16px;
}
.tpicker .ui-icon,
.tpicker .ui-widget-content .ui-icon {
    background-image: url("images/ui-icons_444444_256x240.png")/*{iconsContent}*/;
}
.tpicker .ui-widget-header .ui-icon {
    background-image: url("images/ui-icons_444444_256x240.png")/*{iconsHeader}*/;
}
.tpicker .ui-state-hover .ui-icon,
.tpicker .ui-state-focus .ui-icon,
.tpicker .ui-button:hover .ui-icon,
.tpicker .ui-button:focus .ui-icon {
    background-image: url("images/ui-icons_555555_256x240.png")/*{iconsHover}*/;
}
.tpicker .ui-state-active .ui-icon,
.tpicker .ui-button:active .ui-icon {
    background-image: url("images/ui-icons_ffffff_256x240.png")/*{iconsActive}*/;
}
.tpicker .ui-state-highlight .ui-icon,
.tpicker .ui-button .ui-state-highlight.ui-icon {
    background-image: url("images/ui-icons_777620_256x240.png")/*{iconsHighlight}*/;
}
.tpicker .ui-state-error .ui-icon,
.tpicker .ui-state-error-text .ui-icon {
    background-image: url("images/ui-icons_cc0000_256x240.png")/*{iconsError}*/;
}
.tpicker .ui-button .ui-icon {
    background-image: url("images/ui-icons_777777_256x240.png")/*{iconsDefault}*/;
}

/* positioning */
.tpicker .ui-icon-blank { background-position: 16px 16px; }
.tpicker .ui-icon-caret-1-n { background-position: 0 0; }
.tpicker .ui-icon-caret-1-ne { background-position: -16px 0; }
.tpicker .ui-icon-caret-1-e { background-position: -32px 0; }
.tpicker .ui-icon-caret-1-se { background-position: -48px 0; }
.tpicker .ui-icon-caret-1-s { background-position: -65px 0; }
.tpicker .ui-icon-caret-1-sw { background-position: -80px 0; }
.tpicker .ui-icon-caret-1-w { background-position: -96px 0; }
.tpicker .ui-icon-caret-1-nw { background-position: -112px 0; }
.tpicker .ui-icon-caret-2-n-s { background-position: -128px 0; }
.tpicker .ui-icon-caret-2-e-w { background-position: -144px 0; }
.tpicker .ui-icon-triangle-1-n { background-position: 0 -16px; }
.tpicker .ui-icon-triangle-1-ne { background-position: -16px -16px; }
.tpicker .ui-icon-triangle-1-e { background-position: -32px -16px; }
.tpicker .ui-icon-triangle-1-se { background-position: -48px -16px; }
.tpicker .ui-icon-triangle-1-s { background-position: -65px -16px; }
.tpicker .ui-icon-triangle-1-sw { background-position: -80px -16px; }
.tpicker .ui-icon-triangle-1-w { background-position: -96px -16px; }
.tpicker .ui-icon-triangle-1-nw { background-position: -112px -16px; }
.tpicker .ui-icon-triangle-2-n-s { background-position: -128px -16px; }
.tpicker .ui-icon-triangle-2-e-w { background-position: -144px -16px; }
.tpicker .ui-icon-arrow-1-n { background-position: 0 -32px; }
.tpicker .ui-icon-arrow-1-ne { background-position: -16px -32px; }
.tpicker .ui-icon-arrow-1-e { background-position: -32px -32px; }
.tpicker .ui-icon-arrow-1-se { background-position: -48px -32px; }
.tpicker .ui-icon-arrow-1-s { background-position: -65px -32px; }
.tpicker .ui-icon-arrow-1-sw { background-position: -80px -32px; }
.tpicker .ui-icon-arrow-1-w { background-position: -96px -32px; }
.tpicker .ui-icon-arrow-1-nw { background-position: -112px -32px; }
.tpicker .ui-icon-arrow-2-n-s { background-position: -128px -32px; }
.tpicker .ui-icon-arrow-2-ne-sw { background-position: -144px -32px; }
.tpicker .ui-icon-arrow-2-e-w { background-position: -160px -32px; }
.tpicker .ui-icon-arrow-2-se-nw { background-position: -176px -32px; }
.tpicker .ui-icon-arrowstop-1-n { background-position: -192px -32px; }
.tpicker .ui-icon-arrowstop-1-e { background-position: -208px -32px; }
.tpicker .ui-icon-arrowstop-1-s { background-position: -224px -32px; }
.tpicker .ui-icon-arrowstop-1-w { background-position: -240px -32px; }
.tpicker .ui-icon-arrowthick-1-n { background-position: 1px -48px; }
.tpicker .ui-icon-arrowthick-1-ne { background-position: -16px -48px; }
.tpicker .ui-icon-arrowthick-1-e { background-position: -32px -48px; }
.tpicker .ui-icon-arrowthick-1-se { background-position: -48px -48px; }
.tpicker .ui-icon-arrowthick-1-s { background-position: -64px -48px; }
.tpicker .ui-icon-arrowthick-1-sw { background-position: -80px -48px; }
.tpicker .ui-icon-arrowthick-1-w { background-position: -96px -48px; }
.tpicker .ui-icon-arrowthick-1-nw { background-position: -112px -48px; }
.tpicker .ui-icon-arrowthick-2-n-s { background-position: -128px -48px; }
.tpicker .ui-icon-arrowthick-2-ne-sw { background-position: -144px -48px; }
.tpicker .ui-icon-arrowthick-2-e-w { background-position: -160px -48px; }
.tpicker .ui-icon-arrowthick-2-se-nw { background-position: -176px -48px; }
.tpicker .ui-icon-arrowthickstop-1-n { background-position: -192px -48px; }
.tpicker .ui-icon-arrowthickstop-1-e { background-position: -208px -48px; }
.tpicker .ui-icon-arrowthickstop-1-s { background-position: -224px -48px; }
.tpicker .ui-icon-arrowthickstop-1-w { background-position: -240px -48px; }
.tpicker .ui-icon-arrowreturnthick-1-w { background-position: 0 -64px; }
.tpicker .ui-icon-arrowreturnthick-1-n { background-position: -16px -64px; }
.tpicker .ui-icon-arrowreturnthick-1-e { background-position: -32px -64px; }
.tpicker .ui-icon-arrowreturnthick-1-s { background-position: -48px -64px; }
.tpicker .ui-icon-arrowreturn-1-w { background-position: -64px -64px; }
.tpicker .ui-icon-arrowreturn-1-n { background-position: -80px -64px; }
.tpicker .ui-icon-arrowreturn-1-e { background-position: -96px -64px; }
.tpicker .ui-icon-arrowreturn-1-s { background-position: -112px -64px; }
.tpicker .ui-icon-arrowrefresh-1-w { background-position: -128px -64px; }
.tpicker .ui-icon-arrowrefresh-1-n { background-position: -144px -64px; }
.tpicker .ui-icon-arrowrefresh-1-e { background-position: -160px -64px; }
.tpicker .ui-icon-arrowrefresh-1-s { background-position: -176px -64px; }
.tpicker .ui-icon-arrow-4 { background-position: 0 -80px; }
.tpicker .ui-icon-arrow-4-diag { background-position: -16px -80px; }
.tpicker .ui-icon-extlink { background-position: -32px -80px; }
.tpicker .ui-icon-newwin { background-position: -48px -80px; }
.tpicker .ui-icon-refresh { background-position: -64px -80px; }
.tpicker .ui-icon-shuffle { background-position: -80px -80px; }
.tpicker .ui-icon-transfer-e-w { background-position: -96px -80px; }
.tpicker .ui-icon-transferthick-e-w { background-position: -112px -80px; }
.tpicker .ui-icon-folder-collapsed { background-position: 0 -96px; }
.tpicker .ui-icon-folder-open { background-position: -16px -96px; }
.tpicker .ui-icon-document { background-position: -32px -96px; }
.tpicker .ui-icon-document-b { background-position: -48px -96px; }
.tpicker .ui-icon-note { background-position: -64px -96px; }
.tpicker .ui-icon-mail-closed { background-position: -80px -96px; }
.tpicker .ui-icon-mail-open { background-position: -96px -96px; }
.tpicker .ui-icon-suitcase { background-position: -112px -96px; }
.tpicker .ui-icon-comment { background-position: -128px -96px; }
.tpicker .ui-icon-person { background-position: -144px -96px; }
.tpicker .ui-icon-print { background-position: -160px -96px; }
.tpicker .ui-icon-trash { background-position: -176px -96px; }
.tpicker .ui-icon-locked { background-position: -192px -96px; }
.tpicker .ui-icon-unlocked { background-position: -208px -96px; }
.tpicker .ui-icon-bookmark { background-position: -224px -96px; }
.tpicker .ui-icon-tag { background-position: -240px -96px; }
.tpicker .ui-icon-home { background-position: 0 -112px; }
.tpicker .ui-icon-flag { background-position: -16px -112px; }
.tpicker .ui-icon-calendar { background-position: -32px -112px; }
.tpicker .ui-icon-cart { background-position: -48px -112px; }
.tpicker .ui-icon-pencil { background-position: -64px -112px; }
.tpicker .ui-icon-clock { background-position: -80px -112px; }
.tpicker .ui-icon-disk { background-position: -96px -112px; }
.tpicker .ui-icon-calculator { background-position: -112px -112px; }
.tpicker .ui-icon-zoomin { background-position: -128px -112px; }
.tpicker .ui-icon-zoomout { background-position: -144px -112px; }
.tpicker .ui-icon-search { background-position: -160px -112px; }
.tpicker .ui-icon-wrench { background-position: -176px -112px; }
.tpicker .ui-icon-gear { background-position: -192px -112px; }
.tpicker .ui-icon-heart { background-position: -208px -112px; }
.tpicker .ui-icon-star { background-position: -224px -112px; }
.tpicker .ui-icon-link { background-position: -240px -112px; }
.tpicker .ui-icon-cancel { background-position: 0 -128px; }
.tpicker .ui-icon-plus { background-position: -16px -128px; }
.tpicker .ui-icon-plusthick { background-position: -32px -128px; }
.tpicker .ui-icon-minus { background-position: -48px -128px; }
.tpicker .ui-icon-minusthick { background-position: -64px -128px; }
.tpicker .ui-icon-close { background-position: -80px -128px; }
.tpicker .ui-icon-closethick { background-position: -96px -128px; }
.tpicker .ui-icon-key { background-position: -112px -128px; }
.tpicker .ui-icon-lightbulb { background-position: -128px -128px; }
.tpicker .ui-icon-scissors { background-position: -144px -128px; }
.tpicker .ui-icon-clipboard { background-position: -160px -128px; }
.tpicker .ui-icon-copy { background-position: -176px -128px; }
.tpicker .ui-icon-contact { background-position: -192px -128px; }
.tpicker .ui-icon-image { background-position: -208px -128px; }
.tpicker .ui-icon-video { background-position: -224px -128px; }
.tpicker .ui-icon-script { background-position: -240px -128px; }
.tpicker .ui-icon-alert { background-position: 0 -144px; }
.tpicker .ui-icon-info { background-position: -16px -144px; }
.tpicker .ui-icon-notice { background-position: -32px -144px; }
.tpicker .ui-icon-help { background-position: -48px -144px; }
.tpicker .ui-icon-check { background-position: -64px -144px; }
.tpicker .ui-icon-bullet { background-position: -80px -144px; }
.tpicker .ui-icon-radio-on { background-position: -96px -144px; }
.tpicker .ui-icon-radio-off { background-position: -112px -144px; }
.tpicker .ui-icon-pin-w { background-position: -128px -144px; }
.tpicker .ui-icon-pin-s { background-position: -144px -144px; }
.tpicker .ui-icon-play { background-position: 0 -160px; }
.tpicker .ui-icon-pause { background-position: -16px -160px; }
.tpicker .ui-icon-seek-next { background-position: -32px -160px; }
.tpicker .ui-icon-seek-prev { background-position: -48px -160px; }
.tpicker .ui-icon-seek-end { background-position: -64px -160px; }
.tpicker .ui-icon-seek-start { background-position: -80px -160px; }
/* ui-ic on-seek-first is deprecated, use ui-icon-seek-start instead */
.tpicker .ui-icon-seek-first { background-position: -80px -160px; }
.tpicker .ui-icon-stop { background-position: -96px -160px; }
.tpicker .ui-icon-eject { background-position: -112px -160px; }
.tpicker .ui-icon-volume-off { background-position: -128px -160px; }
.tpicker .ui-icon-volume-on { background-position: -144px -160px; }
.tpicker .ui-icon-power { background-position: 0 -176px; }
.tpicker .ui-icon-signal-diag { background-position: -16px -176px; }
.tpicker .ui-icon-signal { background-position: -32px -176px; }
.tpicker .ui-icon-battery-0 { background-position: -48px -176px; }
.tpicker .ui-icon-battery-1 { background-position: -64px -176px; }
.tpicker .ui-icon-battery-2 { background-position: -80px -176px; }
.tpicker .ui-icon-battery-3 { background-position: -96px -176px; }
.tpicker .ui-icon-circle-plus { background-position: 0 -192px; }
.tpicker .ui-icon-circle-minus { background-position: -16px -192px; }
.tpicker .ui-icon-circle-close { background-position: -32px -192px; }
.tpicker .ui-icon-circle-triangle-e { background-position: -48px -192px; }
.tpicker .ui-icon-circle-triangle-s { background-position: -64px -192px; }
.tpicker .ui-icon-circle-triangle-w { background-position: -80px -192px; }
.tpicker .ui-icon-circle-triangle-n { background-position: -96px -192px; }
.tpicker .ui-icon-circle-arrow-e { background-position: -112px -192px; }
.tpicker .ui-icon-circle-arrow-s { background-position: -128px -192px; }
.tpicker .ui-icon-circle-arrow-w { background-position: -144px -192px; }
.tpicker .ui-icon-circle-arrow-n { background-position: -160px -192px; }
.tpicker .ui-icon-circle-zoomin { background-position: -176px -192px; }
.tpicker .ui-icon-circle-zoomout { background-position: -192px -192px; }
.tpicker .ui-icon-circle-check { background-position: -208px -192px; }
.tpicker .ui-icon-circlesmall-plus { background-position: 0 -208px; }
.tpicker .ui-icon-circlesmall-minus { background-position: -16px -208px; }
.tpicker .ui-icon-circlesmall-close { background-position: -32px -208px; }
.tpicker .ui-icon-squaresmall-plus { background-position: -48px -208px; }
.tpicker .ui-icon-squaresmall-minus { background-position: -64px -208px; }
.tpicker .ui-icon-squaresmall-close { background-position: -80px -208px; }
.tpicker .ui-icon-grip-dotted-vertical { background-position: 0 -224px; }
.tpicker .ui-icon-grip-dotted-horizontal { background-position: -16px -224px; }
.tpicker .ui-icon-grip-solid-vertical { background-position: -32px -224px; }
.tpicker .ui-icon-grip-solid-horizontal { background-position: -48px -224px; }
.tpicker .ui-icon-gripsmall-diagonal-se { background-position: -64px -224px; }
.tpicker .ui-icon-grip-diagonal-se { background-position: -80px -224px; }


/* Misc visuals
----------------------------------*/

/* Corner radius */
.tpicker .ui-corner-all,
.tpicker .ui-corner-top,
.tpicker .ui-corner-left,
.tpicker .ui-corner-tl {
    /*border-top-left-radius: 3px!*{cornerRadius}*!;*/
}
.tpicker .ui-corner-all,
.tpicker .ui-corner-top,
.tpicker .ui-corner-right,
.tpicker .ui-corner-tr {
    /*border-top-right-radius: 3px!*{cornerRadius}*!;*/
}
.tpicker .ui-corner-all,
.tpicker .ui-corner-bottom,
.tpicker .ui-corner-left,
.tpicker .ui-corner-bl {
    /*border-bottom-left-radius: 3px!*{cornerRadius}*!;*/
}
.tpicker .ui-corner-all,
.tpicker .ui-corner-bottom,
.tpicker .ui-corner-right,
.tpicker .ui-corner-br {
    /*border-bottom-right-radius: 3px!*{cornerRadius}*!;*/
}

/* Overlays */
.tpicker .ui-widget-overlay {
    background: #aaaaaa/*{bgColorOverlay}*/ /*{bgImgUrlOverlay}*/ /*{bgOverlayXPos}*/ /*{bgOverlayYPos}*/ /*{bgOverlayRepeat}*/;
    opacity: .3/*{opacityOverlay}*/;
    filter: Alpha(Opacity=30)/*{opacityFilterOverlay}*/; /* support: IE8 */
}
.tpicker .ui-widget-shadow {
    -webkit-box-shadow: 0/*{offsetLeftShadow}*/ 0/*{offsetTopShadow}*/ 5px/*{thicknessShadow}*/ #666666/*{bgColorShadow}*/;
    box-shadow: 0/*{offsetLeftShadow}*/ 0/*{offsetTopShadow}*/ 5px/*{thicknessShadow}*/ #666666/*{bgColorShadow}*/;
}






.ui-timepicker-inline { display: inline; }

#ui-timepicker-div { padding: 0.2em; }
.ui-timepicker-table {
    display: inline-table;
    width: 0;
    box-shadow: 0 0 4px rgba(0,0,0,.3);
    border: 5px solid #176ac4;
}
.ui-timepicker-table table { margin:0.15em 0 0 0; border-collapse: collapse; }

.ui-timepicker-hours .ui-timepicker-title, .ui-timepicker-minutes .ui-timepicker-title {
    background-color: #176ac4;
}
.ui-timepicker-hours, .ui-timepicker-minutes {
    padding: 0.2em;
}
.ui-timepicker-minutes {
    padding-left: 10px !important;
}

.ui-timepicker-table .ui-timepicker-title { line-height: 1.8em; text-align: center; }
.ui-timepicker-table td { padding: 0 0 0.1em 0; width: 2.2em; }
.ui-timepicker-table th.periods { padding: 0.1em; width: 2.2em; }

/* span for disabled cells */
.ui-timepicker-table td span {
    display:block;
    padding:0.2em 0.3em 0.2em 0.5em;

    width: 1.2em;

    text-align:right;
    text-decoration:none;

}
/* anchors for clickable cells */
.ui-timepicker-table td a {
    display: block;
    padding: 0em 0.3em 0em 0em;
    width: 2em;
    cursor: pointer;
    text-align: right;
    text-decoration: none;
}


/* buttons and button pane styling */
.ui-timepicker .ui-timepicker-buttonpane {
    background-image: none; margin: .7em 0 0 0; padding:0 .2em; border-left: 0; border-right: 0; border-bottom: 0;
}
.ui-timepicker .ui-timepicker-buttonpane button { margin: .5em .2em .4em; cursor: pointer; padding: .2em .6em .3em .6em; width:auto; overflow:visible; }
/* The close button */
.ui-timepicker .ui-timepicker-close { float: right }

/* the now button */
.ui-timepicker .ui-timepicker-now { float: left; }

/* the deselect button */
.ui-timepicker .ui-timepicker-deselect { float: left; }

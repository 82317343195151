@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

#ui-datepicker-div{
    z-index: 99999 !important;
}

.ubuntu {
    font-family: 'Ubuntu', sans-serif !important;
}

.flashForms * {
    /*font-size: 85%;*/
    /*font-family: Helvetica, Arial, sans-serif*/
    /*font-family: 'Roboto', sans-serif;*/
}

.flashForms *,
.flashForms ul li {
    /*margin: 0;*/
    /*padding: 0;*/
    list-style: none
}

.note {
    padding: 10px;
    border-left: 6px solid;
    border-radius: 5px
}

.note strong {
    font-weight: 600
}

.note p {
    font-weight: 500
}

.note-primary {
    background-color: #dfeefd;
    border-color: #176ac4
}

.note-secondary {
    background-color: #e2e3e5;
    border-color: #58595a
}

.note-success {
    background-color: #e2f0e5;
    border-color: #49a75f
}

.note-danger {
    background-color: #fae7e8;
    border-color: #e45460
}

.note-warning {
    background-color: #faf4e0;
    border-color: #c2a442
}

.note-info {
    background-color: #e4f2f5;
    border-color: #2492a5
}

.note-light {
    background-color: #fefefe;
    border-color: #0f0f0f
}

.flashForms ul.demo li {
    margin-bottom: 10px
}

.flashForms ul.demo li:last-child {
    margin-bottom: 10px
}

.flashForms input,
.flashForms button,
.flashForms select,
.flashForms select optgroup,
.flashForms textarea {
    margin: 0;
    outline: 0;
    font-size: 1em;
    line-height: normal;
    font-family: Helvetica, Arial, sans-serif;
    font-style: normal;
    vertical-align: baseline;
    *vertical-align: middle
}

.flashForms select optgroup,
.flashForms select optgroup option {
    font-size: 1em;
    padding: 3px
}

.flashForms select optgroup option {
    padding: 3px 8px
}

.flashForms button[disabled],
.flashForms input[disabled] {
    cursor: default
}

.flashForms input[placeholder],
.flashForms textarea[placeholder] {
    /*color: #999*/
}

.flashForms input::-webkit-input-placeholder {
    opacity: .7
}

.flashForms input:focus::-webkit-input-placeholder {
    opacity: .4;
    -webkit-transition: opacity .3s
}

.flashForms input:-ms-input-placeholder {
    opacity: .7
}

.flashForms input:focus:-ms-input-placeholder {
    opacity: .4;
    -ms-transition: opacity .3s
}

.flashForms input:-moz-placeholder,
input::-moz-placeholder {
    opacity: .7
}

.flashForms input:focus:-moz-placeholder,
.flashForms input:focus::-moz-placeholder {
    opacity: .4;
    -moz-transition: opacity .3s
}

.flashForms button::-moz-focus-inner,
.flashForms input::-moz-focus-inner {
    border: 0;
    padding: 0
}

.flashForms .toggle-switch,
.flashForms .options,
.flashForms .button,
.flashForms a.button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.flashForms * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none
}

.gforms {
    padding: 40px 20px
}

.flashForms.wrapper {
    margin: 0 auto;
    background: #fff;
    max-width: 600px;
    position: relative;
    border-radius: 5px;
    /*border-bottom: 1px solid #c4c4c4;*/
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.55);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.55);
    border: 5px solid #d9534f;
    /*border: 3px solid #0a70c6;*/
}

.flashForms.wrapper.mini {
    max-width: 380px
}

.flashForms.wrapper.wide {
    max-width: 840px
}

.flashForms.wrapper:before,
.flashForms.wrapper:after {
    content: '';
    position: absolute;
    z-index: 99;
    bottom: 1px;
    left: 0;
    right: 0;
    height: 10px;
    background: #f7f7f7;
    border-bottom: 1px solid #d2d2d2;
    border-radius: 5px
}

.flashForms.wrapper:after {
    bottom: 3px;
    border-color: #dcdcdc
}

.flashForms .form-title {
    padding: 15px 25px;
    padding-bottom: 23px;
    font-weight: 300;
    text-align: center;
    text-shadow: 0 1px rgba(255, 255, 255, 0.75);
    background: #f7f7f7;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #c1c1c1;
}

.flashForms .form-title h2 {
    color: #999;
    font-family: "Source Sans Pro", "Segoe UI Light", Helvetica, Arial, sans-serif;
    font-size: 28px;
    font-weight: 300;
    line-height: normal;
    margin: 0;
    padding: 0
}

.flashForms .form-enclose {
    background: #fff;
    border-radius: 0 0 6px 6px
}

.flashForms .form-section {
    padding: 30px;
    padding-bottom: 0
}

.flashForms section {
    margin-bottom: 10px;
    position: relative
}

.flashForms label,
.flashForms p span.infox {
    color: #000;
    font-size: 1em;
    font-weight: bold;
}

.flashForms .lbl-ui {
    position: relative;
    display: block
}

.flashForms .lbl-text {
    color: #333;
    display: block;
    font-size: 10px;
    letter-spacing: 1px;
    margin-bottom: 0px;
    text-transform: uppercase;
    margin-top: 5px;
}

.flashForms .lbl-text.required:after {
    content: " *";
    font-size: 15px;
    color: #e32;
    display: inline;
    position: relative;
    top: 4px
}

.flashForms .lbl-text.ext {
    margin-bottom: 10px
}

.flashForms span.lbl-text {
    margin: 0;
    display: -moz-inline-stack;
    display: inline-block;
    *display: inline;
    /*zoom: 1*/
}

.flashForms .lbl-text.tleft,
.flashForms .lbl-valign {
    display: block;
    padding-top: 14px
}

.flashForms .lbl-valign.long {
    padding-top: 40px
}

.flashForms .lbl-text.tleft.multi-line {
    padding-top: 0
}

.flashForms .lbl-text.tleft.trt {
    text-align: right
}

.flashForms .lbl-text.spacer {
    margin-bottom: 15px
}

.flashForms .option-group .goption,
.flashForms .toggle-group .gtoggle {
    margin-bottom: 8px;
    margin-right: 15px;
    display: inline-block;
    display: inline;
    /*zoom: 1*/
}

.flashForms .option-group.block .goption,
.flashForms .toggle-group.block .gtoggle {
    display: block;
    margin-right: 0
}

.flashForms .goption.upt {
    position: relative;
    top: -5px
}

.flashForms .option-group.last,
.flashForms .toggle-group.last {
    padding-bottom: 10px;
    border-bottom: 0
}

.flashForms .form-buttons section {
    margin: 0;
    padding: 25px 30px;
    background: #f7f7f7;
    border-radius: 0 0 6px 6px
}

.flashForms .form-buttons section {
    margin: 0
}

.flashForms .append-icon,
.flashForms .append-icon.art {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 100%
}

.flashForms .append-icon span {
    top: 0;
    left: 0;
    z-index: 1;
    width: 36px;
    height: 36px;
    color: inherit;
    line-height: 36px;
    position: absolute;
    text-align: center;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
    pointer-events: none
}

.flashForms .append-icon span i {
    position: relative;
    font-size: 14px
}

.flashForms .append-icon.art span {
    left: auto;
    right: 0
}

.flashForms .append-icon .input,
.flashForms .append-icon .textarea {
    padding-left: 30px
}

.flashForms .append-icon.art .input,
.flashForms .append-icon.art .textarea {
    padding-right: 30px;
    padding-left: 10px
}

.flashForms .glink {
    color: #0093d6
}

.flashForms .glink.ext {
    margin-left: 10px
}

.flashForms .glink:hover {
    text-decoration: underline
}

.flashForms .gspace {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd
}

.flashForms .gspace.blank {
    padding-bottom: 0;
    border-bottom: 0
}

.flashForms .social-btns {
    width: 100%
}

.flashForms .social-btns a {
    display: inline-block;
    position: relative;
    width: 49%;
    height: 46px;
    line-height: 46px;
    color: #fff;
    background: #1daee3;
    font-size: 1.4em;
    font-weight: bold;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px
}

.flashForms .social-btns a span,
.flashForms .social-btns a i {
    display: inline-block;
    line-height: 46px;
    text-align: center;
    height: 46px;
    width: 46px;
    position: absolute;
    left: 0;
    top: 0
}

.flashForms .social-btns a span {
    position: relative;
    padding-left: 60px;
    width: auto !important
}

.flashForms .social-btns a i {
    font-size: 22px;
    background: #1ca4d6;
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    -ms-border-radius: 3px 0 0 3px;
    -o-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px
}

.flashForms .small-caption {
    display: block;
    padding: 3px 5px;
    padding-bottom: 0;
    font-size: .95em;
    color: #999;
    text-transform: none
}

.flashForms .social-btns a.fbs {
    margin-right: 0;
    background: #3b5998
}

.flashForms .social-btns a.fbs i {
    background: #37528c
}

.flashForms .social-btns.full a {
    width: 100%;
    margin-right: 0 !important
}

.flashForms .form-buttons.align-center {
    text-align: center
}

.flashForms .form-buttons.align-right {
    text-align: right
}

.flashForms .form-buttons .button {
    margin-right: 10px;
    text-align: center
}

.flashForms .form-buttons.align-right .button {
    margin-left: 10px;
    margin-right: 0
}

.flashForms .cxb {
    display: block;
    text-align: center;
    text-transform: uppercase
}

.flashForms .tagline {
    border-bottom: 1px solid #ccc;
    text-align: center
}

.flashForms .tagline span {
    display: inline-block;
    *display: inline;
    /*zoom: 1;*/
    font-size: 12px;
    color: #666;
    font-weight: bold;
    background: #fff;
    padding: 3px 5px;
    position: relative;
    top: 10px
}

.flashForms form fieldset {
    padding-top: 20px;
    border: 1px solid #ddd;
    border-width: 1px 0 0 0
}

.flashForms form fieldset legend {
    position: relative;
    background: #fff;
    font-family: "Source Sans Pro", "Segoe UI Light", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.6em;
    color: #0093d6;
    margin-left: -20px;
    top: -5px;
    margin-top: 0;
    padding: 0 5px;
    display: inline-block;
    *display: inline;
    /*zoom: 1*/
}

/*
.row:before,
.row:after {
    content: '';
    display: table
}*/
/*
.row:after {
    clear: both
}*/
/*
.row {
    zoom: 1
}*/

/*.row {
    width: 100%;
    margin: 0 auto
}*/


.flashForms .input,
.flashForms .valid,
.flashForms .rating,
.flashForms .select,
.flashForms .button,
.flashForms .invalid,
.flashForms .options,
.flashForms .textarea,
.flashForms .info-tip,
.flashForms .file-input,
.flashForms .notification,
.flashForms .toggle-switch,
.flashForms .select-multiple,
.flashForms .select-multiple > select {
    position: relative;
    display: -moz-inline-stack;
    display: inline-block;
    *display: inline;
    /*zoom: 1*/
}

.flashForms .input,
.flashForms .valid,
.flashForms .select,
.flashForms .invalid,
.flashForms .textarea,
.flashForms .file-input,
.flashForms .notification,
.flashForms .select-multiple > select {
    width: 100%;
    vertical-align: top
}

.flashForms .input,
.flashForms .input:disabled,
.flashForms .textarea {
    height: 36px;
    color: #333;
    padding: 0 10px;
    background: #fff;
    border: 1px solid;
    border-color: #bbb;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    -o-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
}

.flashForms select:disabled {
    background-color: #e9ecef !important;
    cursor: not-allowed !important;
    border-color: #ced4da !important;
    box-shadow: none !important;
}


.flashForms input::-moz-placeholder {
    color: #888;
    opacity: 1;
    font-size: 12px;
}

.flashForms textarea::-moz-placeholder {
    color: #888;
    opacity: 1;
    font-size: 12px;
}

.flashForms input:-ms-input-placeholder {
    color: #888;
    font-size: 12px;

}

.flashForms textarea:-ms-input-placeholder {
    color: #888;
    font-size: 12px;

}

.flashForms textarea::-webkit-input-placeholder {
    color: #888;
    font-size: 12px;
}

.flashForms input::-webkit-input-placeholder {
    color: #888;
    font-size: 12px;
}

.flashForms .input:focus,
.flashForms .textarea:focus,
.flashForms .select-multiple > select:focus {
    border-color: #d9534f;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15), 0 0 8px #d9534f;
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15), 0 0 8px #d9534f;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15), 0 0 8px #d9534f;

}

.flashForms .textarea {
    padding: 10px;
    overflow: auto;
    line-height: 20px;
    min-height: 75px;
    max-width: 100%;
    resize: vertical
}

.flashForms .textarea.mini {
    min-height: 110px
}

.flashForms .textarea.auto {
    height: auto !important
}

.flashForms .textarea.no-resize {
    resize: none
}

.flashForms .invalid > .input,
.flashForms .invalid > .textarea {
    color: #8f2424;
    background: #faebeb;
    border: 1px solid #db6c6c
}

.flashForms .valid > .input,
.flashForms .valid > .textarea {
    color: #1a719d;
    background: #ebf7fc;
    border: 1px solid #3ea9df
}

.flashForms .valid > .input:focus,
.flashForms .invalid > .input:focus {
    position: relative;
    z-index: 2
}

.flashForms .valid:before,
.flashForms .search:after,
.flashForms .invalid:after,
.flashForms .search:before,
.flashForms .invalid:before,
.flashForms .select:before,
.flashForms .select:after {
    content: '';
    position: absolute;
    z-index: 1
}

.flashForms .valid:before {
    top: 13px;
    right: 12px;
    width: 10px;
    height: 4px;
    border: solid #069;
    border-width: 0 0 3px 3px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.flashForms .invalid:before,
.flashForms .invalid:after {
    top: 13px;
    right: 16px;
    width: 3px;
    height: 12px;
    background: #c06;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.flashForms .invalid:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.flashForms .valid.success > .input,
.flashForms .invalid.error > .input {
    text-shadow: 0 1px rgba(255, 255, 255, 0.7)
}

.flashForms .valid.success > .input {
    color: #435c26;
    background: #e2fbca;
    border-color: #92c058
}

.flashForms .valid.success > .input:focus {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15), 0 0 8px rgba(121, 185, 0, .7);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15), 0 0 8px rgba(121, 185, 0, .7);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15), 0 0 8px rgba(121, 185, 0, .7)
}

.flashForms .invalid > .input:focus {
    /*-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15), 0 0 8px rgba(228, 70, 52, 0, .7);*/
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15), 0 0 8px rgba(228, 70, 52, 0.7);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15), 0 0 8px rgba(228, 70, 52, .7)
}

.flashForms .invalid.error > .input {
    color: maroon;
    background: #fcdacd;
    border-color: #e44634
}

.flashForms .valid.success:before {
    border-color: #4f8e28
}

.flashForms .invalid.error:before,
.flashForms .invalid.error:after {
    background: #ad3925
}

.flashForms input[type=search] {
    -webkit-appearance: textfield
}

.flashForms input[type="search"]::-webkit-search-decoration,
.flashForms input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none
}

.flashForms .search {
    position: relative;
    display: inline-block;
    width: 100%
}

.flashForms .search > .input {
    width: 100%;
    padding: 0 13px 0 30px
}

.flashForms .search:before {
    top: 13px;
    left: 10px;
    width: 6px;
    height: 6px;
    border: 2px solid #aaa;
    border-radius: 8px
}

.flashForms .search:after {
    top: 22px;
    left: 17px;
    width: 6px;
    height: 2px;
    background: #aaa;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-box-shadow: -1px 0 #aaa;
    box-shadow: -1px 0 #aaa
}

.flashForms .search .button {
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topleft: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    position: absolute;
    box-shadow: none;
    right: 0;
    top: 0
}

.flashForms .search.grey input[type="search"] {
    background: #f2f2f2
}

.flashForms .search.round > .input,
.flashForms .search.round > .button {
    border-radius: 5em
}

.flashForms .search.round > .button {
    border-radius: 0 5em 5em 0
}

.flashForms .select,
.flashForms .button,
.flashForms a.button,
.flashForms input:checked + .radio,
.flashForms input:checked + .checkbox,
.flashForms .toggle-switch > label:before,
.flashForms .bubble {
    border: 1px solid #bbb;
    background-color: #fafafa;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#e6e6e6));
    background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
    background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
    background-image: -o-linear-gradient(top, #fff, #e6e6e6);
    background-image: linear-gradient(to bottom, #fff, #e6e6e6);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box
}

.flashForms .select:hover,
.flashForms .select:focus,
.flashForms .button:hover,
.flashForms .button:focus,
.flashForms a.button:hover,
.flashForms a.button:focus {
    border: 1px solid #8fafc9;
    background-color: #e8eff4;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#dae5ed));
    background-image: -webkit-linear-gradient(#fff, #dae5ed);
    background-image: -moz-linear-gradient(#fff, #dae5ed);
    background-image: -o-linear-gradient(#fff, #dae5ed);
    background-image: linear-gradient(to bottom, #fff, #dae5ed)
}

.flashForms .select:hover,
.flashForms .select:focus {
    background-color: #fff
}

.flashForms .select {
    height: 35px;
    overflow: hidden;
    -webkit-box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
    text-shadow: 0 1px rgba(255, 255, 255, 0.7);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.flashForms .select > select {
    margin: 0;
    border: 0;
    width: 130%;
    height: 33px;
    padding: 8px;
    color: #000;
    display: block;
    position: relative;
    text-shadow: 0 1px white;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: .01px;
    text-overflow: '';
    font-weight: bold;
    font-size: 1rem;
}

.flashForms .select > select::-ms-expand {
    display: none
}

.flashForms .select > select > option {
    padding: 3px 5px
}

.flashForms .select > select:focus {
    z-index: 2;
    width: 100%;
    -webkit-box-shadow: inset 0 0 4px #c2f1ff, 0 0 4px #c2f1ff;
    -moz-box-shadow: inset 0 0 4px #c2f1ff, 0 0 4px #c2f1ff;
    box-shadow: inset 0 0 4px #c2f1ff, 0 0 4px #c2f1ff
}

.flashForms .select:hover,
.flashForms .select:focus {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px #fff;
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px #fff;
    color: #314f64;
    z-index: 1
}

.flashForms .select option {
    text-shadow: none
}

.flashForms .select:before,
.flashForms .select:after {
    top: 35%;
    width: 0;
    height: 0;
    right: 10px;
    border: 4px dashed;
    pointer-events: none;
    border-color: #777 transparent
}

.flashForms .select:before {
    border-bottom-style: solid;
    border-top: 0
}

.flashForms .select:after {
    margin-top: 6px;
    border-top-style: solid;
    border-bottom: 0
}

.flashForms .select-multiple {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.flashForms .select-multiple {
    width: 100%
}

.flashForms .select-multiple {
    height: auto
}

.flashForms .select-multiple > select {
    padding: 10px;
    border: 1px solid;
    vertical-align: top;
    border-color: #d4d4d4 #d4d4d4 #cfcfcf;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px
}

.flashForms .options {
    top: -1px;
    width: 18px;
    height: 18px;
    vertical-align: top
}

.flashForms .options + label {
    padding-left: 4px;
    padding-right: 10px
}

.flashForms .options > input {
    position: absolute;
    width: inherit;
    height: inherit;
    opacity: 0;
    left: 0
}

.flashForms .radio,
.flashForms .checkbox {
    display: block;
    height: 100%;
    background: #fff;
    border: 1px solid;
    border-color: #bbb;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.7);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.7);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.7);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px
}

.flashForms .checkbox:before,
.flashForms .radio:before {
    content: '';
    display: none
}

.flashForms input:checked + .checkbox,
.flashForms input:checked + .radio {
    -webkit-box-shadow: inset 0 1px #fbfbfb, 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px #fbfbfb, 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px #fbfbfb, 0 1px 1px rgba(0, 0, 0, 0.05)
}

.flashForms input:checked + .checkbox:before,
.flashForms input:checked + .radio:before {
    display: block
}

.flashForms input:focus + .checkbox,
.flashForms input:focus + .radio {
    border-color: #4496e7;
    -webkit-box-shadow: inset 0 0 2px rgba(81, 181, 251, 0.8), 0 0 3px rgba(81, 181, 251, 0.8);
    -moz-box-shadow: inset 0 0 2px rgba(81, 181, 251, 0.8), 0 0 3px rgba(81, 181, 251, 0.8);
    box-shadow: inset 0 0 2px rgba(81, 181, 251, 0.8), 0 0 3px rgba(81, 181, 251, 0.8)
}

.flashForms .checkbox:before {
    position: absolute;
    top: 5px;
    left: 4px;
    width: 7px;
    height: 3px;
    border: solid #999;
    border-width: 0 0 3px 3px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.flashForms .radio {
    border-radius: 18px
}

.flashForms .radio:before {
    margin: 5px;
    width: 6px;
    height: 6px;
    background: #999;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.1)
}

.flashForms .toggle-switch {
    vertical-align: top;
    width: 48px;
    top: -2px
}

.flashForms .toggle-switch > label {
    display: block;
    position: relative;
    height: 18px;
    line-height: 18px;
    font-size: 10px;
    font-weight: bold;
    color: #999;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 1px #fff;
    background: #fff;
    border: 1px solid #BBB;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 9px #eee;
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 9px #eee;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 9px #eee;
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out
}

.flashForms .toggle-switch > label:before {
    content: '';
    display: block;
    margin: -1px 0 0 -1px;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.7), 1px 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px rgba(255, 255, 255, 0.7), 1px 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.7), 1px 1px 1px rgba(0, 0, 0, 0.05);
    background-clip: padding-box
}

.flashForms .toggle-switch > label:after {
    content: attr(data-off);
    position: absolute;
    width: 26px;
    right: 0;
    top: 0
}

.flashForms .toggle-switch > input {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 0;
    padding: 0;
    opacity: 0;
    width: 44px;
    height: 16px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.flashForms .toggle-switch > input:checked + label {
    color: #999;
    padding-left: 28px;
    background: #e8e8e8;
    background-image: -webkit-linear-gradient(top, #e2e2e2, #fff);
    background-image: -moz-linear-gradient(top, #e2e2e2, #fff);
    background-image: -o-linear-gradient(top, #e2e2e2, #fff);
    background-image: linear-gradient(to bottom, #e2e2e2, #fff);
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.2);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.2);
    text-shadow: 0 1px #fff
}

.flashForms .toggle-switch > input:checked + label:before {
    border-color: rgba(119, 119, 119, 0.4);
    border-left-color: rgba(51, 51, 51, 0.4);
    -webkit-box-shadow: inset 0 1px #fcfcfc, -1px 1px 1px rgba(0, 0, 0, 0.06);
    box-shadow: inset 0 1px #fcfcfc, 1px 1px 1px rgba(0, 0, 0, 0.06)
}

.flashForms .toggle-switch > label:after {
    top: -1px;
    right: 2px;
    width: 26px;
    content: attr(data-off);
    position: absolute
}

.flashForms .toggle-switch > input:checked + label:after {
    left: 0;
    content: attr(data-on)
}

.flashForms .round > label,
.flashForms .round > label:before {
    border-radius: 18px
}

.flashForms .round > input:checked + label:after {
    left: 2px
}

.flashForms .round > label:before {
    color: #e4e4e4;
    content: "\2022";
    font: 34px/17px Arial, Helvetica, sans-serif !important
}

.flashForms .toggle-switch.blue > input:checked + label,
.flashForms .toggle-switch.green > input:checked + label,
.flashForms .toggle-switch.red > input:checked + label {
    color: #fff;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px rgba(255, 255, 255, 0.1)
}

.flashForms .toggle-switch.blue > input:checked + label {
    background-color: #d9534f;
    border-color: #a20500 #d9534f #b02320;
    background-image: -webkit-linear-gradient(top, #b7110c, #f9312b);
    background-image: -moz-linear-gradient(top, #b7110c, #f9312b);
    background-image: -o-linear-gradient(top, #b7110c, #f9312b);
    background-image: linear-gradient(180deg,#b7110c,#f9312b);
}

.flashForms .toggle-switch.blue > input:checked + label:before {
    border-color: #7b0000 !important;
    text-shadow: 0 1px #fff;
}

.flashForms .toggle-switch.green > input:checked + label {
    background-color: #86c140;
    border-color: #678f3a #75a143 #81b049;
    background-image: -webkit-linear-gradient(top, #71b533, #a7d757);
    background-image: -moz-linear-gradient(top, #71b533, #a7d757);
    background-image: -o-linear-gradient(top, #71b533, #a7d757);
    background-image: linear-gradient(to bottom, #71b533, #a7d757)
}

.flashForms .toggle-switch.green > input:checked + label:before {
    text-shadow: 0 1px #fff;
    border-color: #75a143 !important
}

.flashForms .toggle-switch.red > input:checked + label {
    background-color: #de472f;
    border-color: #a60000 #c10000 #e14a42;
    background-image: -webkit-linear-gradient(top, #d00, #f96);
    background-image: -moz-linear-gradient(top, #d00, #f96);
    background-image: -o-linear-gradient(top, #d00, #f96);
    background-image: linear-gradient(to bottom, #d00, #f96)
}

.flashForms .toggle-switch.red > input:checked + label:before {
    text-shadow: 0 1px #fff;
    border-color: #de472f !important
}

.flashForms .file-input {
    margin: 0
}

.flashForms .file-input .button {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    height: 36px;
    margin: 0;
    padding: 0 16px;
    line-height: 35px;
    box-shadow: none;
    z-index: 2;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topleft: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.flashForms .file-input .button:hover {
    box-shadow: none
}

.flashForms .file-input .button:active {
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.1)
}

.flashForms .file-input .button input {
    position: absolute;
    top: -1px;
    right: 0;
    padding: 0;
    font-size: 30px;
    height: 35px;
    cursor: pointer;
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: 'alpha(opacity=0)';
    z-index: 3
}

.flashForms .rating {
    overflow: hidden
}

.flashForms .rating.block {
    display: block;
    margin-top: 5px
}

.flashForms .rating label {
    color: #d0d0d0
}

.flashForms .rating label i {
    font-size: 17px;
    text-align: center;
    color: inherit
}

.flashForms .rating-star {
    margin-left: 4px
}

.flashForms .rating-input {
    position: absolute;
    left: -9999px;
    top: auto
}

.flashForms .rating:hover .rating-star:hover,
.flashForms .rating:hover .rating-star:hover ~ .rating-star,
.flashForms .rating-input:checked ~ .rating-star {
    color: #ffb400
}

.flashForms .rating-star,
.flashForms .rating:hover .rating-star {
    width: 18px;
    height: 18px;
    float: right;
    display: block;
    cursor: pointer;
    color: #d0d0d0
}

.flashForms .button i {
    font-size: 14px
}

.flashForms .button,
.flashForms a.button {
    outline: 0;
    width: auto;
    height: 36px;
    color: #4b5151;
    line-height: 1;
    padding: 0 15px;
    cursor: pointer;
    font-size: 13px;
    overflow: visible;
    text-align: center;
    vertical-align: top;
    -webkit-box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
    text-shadow: 0 1px rgba(255, 255, 255, 0.7);
    -webkit-background-clip: padding;
    /*-moz-background-clip: padding;*/
    background-clip: padding-box;
    -webkit-touch-callout: none;
    -webkit-appearance: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.flashForms a.button {
    line-height: 36px;
    text-decoration: none
}

.flashForms .button:hover,
.flashForms .button:focus,
.flashForms a.button:hover,
.flashForms a.button:focus {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px #fff;
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px #fff;
    color: #314f64
}

.flashForms .button:active,
.flashForms .button.active,
.flashForms a.button:active,
.flashForms a.button.active,
.flashForms .button.next-btn:active,
.flashForms .button.prev-btn:active {
    background-color: #e8e8e8;
    border-color: #bfbfbf #bfbfbf #d6d6d6;
    background-image: -webkit-linear-gradient(top, #e8e8e8, #fff);
    background-image: -moz-linear-gradient(top, #e8e8e8, #fff);
    background-image: -o-linear-gradient(top, #e8e8e8, #fff);
    background-image: linear-gradient(to bottom, #e8e8e8, #fff);
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15), 0 1px rgba(255, 255, 255, 0.1)
}

.flashForms .button.blue,
.flashForms .button.blue:hover,
.flashForms .button.green,
.flashForms .button.green:hover,
.flashForms .button.red,
.flashForms .button.red:hover,
.flashForms .button.black,
.flashForms .button.black:hover {
    color: #fff;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06)
}

.flashForms .button.blue:hover,
.flashForms .button.blue:focus,
.flashForms .button.blue:active,
.flashForms .button.green:hover,
.flashForms .button.green:focus,
.flashForms .button.green:active,
.flashForms .button.red:hover,
.flashForms .button.red:focus,
.flashForms .button.red:active,
.flashForms .button.black:hover,
.flashForms .button.black:focus,
.flashForms .button.black:active {
    color: #fff
}

.flashForms .button.blue:active,
.flashForms .button.green:active,
.flashForms .button.red:active,
.flashForms .button.black:active {
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px rgba(255, 255, 255, 0.1)
}

.flashForms .button.blue,
.flashForms .bubble.blue {
    background-color: #208ed3;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #4baadc), color-stop(100%, #074dda));
    background-image: -webkit-linear-gradient(top, #4baadc, #074dda);
    background-image: -moz-linear-gradient(top, #4baadc, #074dda);
    background-image: -o-linear-gradient(top, #4baadc, #074dda);
    background-image: -ms-linear-gradient(top, #4baadc, #074dda);
    background-image: linear-gradient(to bottom, #4baadc, #074dda);
    border-color: #1f58cc #1b4db3 #1b4db3
}

.flashForms .button.blue:hover,
.flashForms .button.blue:focus {
    background-color: #1e55c8;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #2160de), color-stop(100%, #1e55c8));
    background-image: -webkit-linear-gradient(top, #2160de, #1e55c8);
    background-image: -moz-linear-gradient(top, #2160de, #1e55c8);
    background-image: -o-linear-gradient(top, #2160de, #1e55c8);
    background-image: -ms-linear-gradient(top, #2160de, #1e55c8);
    background-image: linear-gradient(to bottom, #2160de, #1e55c8);
    border-color: #174299 #174299 #174299
}

.flashForms .button.blue:active {
    background-color: #0d4dc1;
    border-color: #0f3779 #0d4dc1 #0f58e1;
    background-image: -webkit-linear-gradient(top, #0d4dc1, #48a4ff);
    background-image: -moz-linear-gradient(top, #0d4dc1, #48a4ff);
    background-image: -o-linear-gradient(top, #0d4dc1, #48a4ff);
    background-image: linear-gradient(to bottom, #0d4dc1, #48a4ff)
}

.flashForms .button.green,
.flashForms .bubble.green {
    background-color: #7fbf4d;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #a1d254), color-stop(100%, #63a62f));
    background-image: -webkit-linear-gradient(top, #a1d254, #63a62f);
    background-image: -moz-linear-gradient(top, #a1d254, #63a62f);
    background-image: -ms-linear-gradient(top, #a1d254, #63a62f);
    background-image: -o-linear-gradient(top, #a1d254, #63a62f);
    background-image: linear-gradient(to bottom, #a1d254, #63a62f);
    text-shadow: 0 -1px 0 #4c9021;
    border: 1px solid #457621;
    border-top: 1px solid #5b992b
}

.flashForms .button.green:hover,
.flashForms .button.green:focus {
    background-color: #76b347;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #76b347), color-stop(100%, #5e9e2e));
    background-image: -webkit-linear-gradient(top, #76b347, #5e9e2e);
    background-image: -moz-linear-gradient(top, #76b347, #5e9e2e);
    background-image: -ms-linear-gradient(top, #76b347, #5e9e2e);
    background-image: -o-linear-gradient(top, #76b347, #5e9e2e);
    background-image: linear-gradient(to bottom, #76b347, #5e9e2e);
    border: 1px solid #457621;
    border-top: 1px solid #5b992b
}

.flashForms .button.green:active {
    background-color: #548e28;
    border-color: #678f3a #75a143 #81b049;
    background-image: -webkit-linear-gradient(top, #71b533, #a7d757);
    background-image: -moz-linear-gradient(top, #71b533, #a7d757);
    background-image: -o-linear-gradient(top, #71b533, #a7d757);
    background-image: linear-gradient(to bottom, #71b533, #a7d757)
}

.flashForms .button.red,
.flashForms .bubble.red {
    background-color: #cf3025;
    border-color: #be1814 #ac1612 #ac1612;
    background-image: -webkit-linear-gradient(top, #fc4138, #cf3025);
    background-image: -moz-linear-gradient(top, #fc4138, #cf3025);
    background-image: -o-linear-gradient(top, #fc4138, #cf3025);
    background-image: linear-gradient(to bottom, #fc4138, #cf3025)
}

.flashForms .button.red:hover,
.flashForms .button.red:focus {
    background-color: #bc2d21;
    border-color: #be1814 #ac1612 #ac1612;
    background-image: -webkit-linear-gradient(top, #f81003, #bc2d21);
    background-image: -moz-linear-gradient(top, #f81003, #bc2d21);
    background-image: -o-linear-gradient(top, #f81003, #bc2d21);
    background-image: linear-gradient(to bottom, #f81003, #bc2d21)
}

.flashForms .button.red:active {
    background-color: #a60000;
    border-color: #a60000 #c10000 #e14a42;
    background-image: -webkit-linear-gradient(top, #d00, #f96);
    background-image: -moz-linear-gradient(top, #d00, #f96);
    background-image: -o-linear-gradient(top, #d00, #f96);
    background-image: linear-gradient(to bottom, #d00, #f96)
}

.flashForms .button.black,
.flashForms .bubble.black {
    background-color: #333;
    border-color: #3e3e3e #222 #030303;
    background-image: -webkit-linear-gradient(top, #4f4f4f, #181818);
    background-image: -moz-linear-gradient(top, #4f4f4f, #181818);
    background-image: -o-linear-gradient(top, #4f4f4f, #181818);
    background-image: linear-gradient(to bottom, #4f4f4f, #181818);
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.03), inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: inset 0 1px rgba(255, 255, 255, 0.03), inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.03), inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06)
}

.flashForms .button.black:hover,
.flashForms .button.black:focus {
    background-color: #111;
    background-image: -webkit-linear-gradient(top, #333, #111);
    background-image: -moz-linear-gradient(top, #333, #111);
    background-image: -o-linear-gradient(top, #333, #111);
    background-image: linear-gradient(to bottom, #333, #111);
    border: 1px solid #2c2b2b
}

.flashForms .button.black:active {
    background-color: #070707;
    background-image: -webkit-linear-gradient(top, #070707, #444);
    background-image: -moz-linear-gradient(top, #070707, #444);
    background-image: -o-linear-gradient(top, #070707, #444);
    background-image: linear-gradient(to bottom, #070707, #444);
    border: 1px solid #2c2b2b
}

.flashForms .notification {
    color: #444;
    padding: 12px;
    border: 1px solid;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px rgba(255, 255, 255, 0.4) inset;
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px rgba(255, 255, 255, 0.4) inset;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1px rgba(255, 255, 255, 0.4) inset;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

.flashForms .notification p {
    margin: 0;
    padding: 0 10px;
    line-height: normal
}

.flashForms .notification a.close {
    margin-top: -7px;
    padding: inherit;
    position: absolute;
    font: bold 20px/20px Arial, sans-serif;
    opacity: .65;
    color: inherit;
    display: block;
    right: 2px;
    top: 14%
}

.flashForms .notification a.close:hover {
    opacity: 1
}

.flashForms .notification.info {
    color: #163161;
    background-color: #cfe6fc;
    border-color: #a4c8f5 #96c0f3 #96c0f3;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #e7f2fe), color-stop(100%, #c7e1fc));
    background-image: -webkit-linear-gradient(top, #e7f2fe, #c7e1fc);
    background-image: -moz-linear-gradient(top, #e7f2fe, #c7e1fc);
    background-image: -o-linear-gradient(top, #e7f2fe, #c7e1fc);
    background-image: linear-gradient(to bottom, #e7f2fe, #c7e1fc)
}

.flashForms .notification.success {
    color: #363;
    background-color: #d2f7ad;
    border-color: #aedb2e;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #e4fbce), color-stop(100%, #cef7a6));
    background-image: -webkit-linear-gradient(top, #e4fbce, #cef7a6);
    background-image: -moz-linear-gradient(top, #e4fbce, #cef7a6);
    background-image: -o-linear-gradient(top, #e4fbce, #cef7a6);
    background-image: linear-gradient(to bottom, #e4fbce, #cef7a6)
}

.flashForms .notification.warning {
    color: #c60;
    background-color: #fae7a2;
    border-color: #f5c558;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ffffc6), color-stop(100%, #fae49c));
    background-image: -webkit-linear-gradient(top, #ffffc6, #fae49c);
    background-image: -moz-linear-gradient(top, #ffffc6, #fae49c);
    background-image: -o-linear-gradient(top, #ffffc6, #fae49c);
    background-image: linear-gradient(to bottom, #ffffc6, #fae49c)
}

.flashForms .notification.error {
    color: #900;
    background-color: #ffd5af;
    border-color: #f8b472;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ffe7d3), color-stop(100%, #ffd1a8));
    background-image: -webkit-linear-gradient(top, #ffe7d3, #ffd1a8);
    background-image: -moz-linear-gradient(top, #ffe7d3, #ffd1a8);
    background-image: -o-linear-gradient(top, #ffe7d3, #ffd1a8);
    background-image: linear-gradient(to bottom, #ffe7d3, #ffd1a8)
}

.flashForms .bubble {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    position: absolute;
    font-weight: bold;
    line-height: 2em;
    height: 2em;
    width: 2em;
    left: -40px;
    top: -7px
}

.flashForms .bubble.blue,
.flashForms .bubble.black,
.flashForms .bubble.green,
.flashForms .bubble.red {
    color: #fff;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), inset 0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.06)
}

.flashForms .info-tip {
    vertical-align: middle;
    *vertical-align: auto;
    overflow: hidden;
    outline: 0;
    margin: 0;
    padding: 0;
    left: 2px;
    top: -2px;
    width: 18px;
    height: 18px;
    color: #FFF;
    line-height: 19px;
    font-weight: bold;
    text-align: center;
    background: #444;
    text-decoration: none;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    cursor: help
}

.flashForms .info-tip span {
    border: 0;
    opacity: 0;
    width: 15em;
    color: #fff;
    display: block;
    position: absolute;
    left: -10px;
    bottom: 2.2em;
    z-index: 99;
    text-align: left;
    font-weight: normal;
    padding: 1em 1em;
    margin-top: 20px;
    background: #333;
    background: rgba(0, 0, 0, .8);
    -webkit-box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.34);
    -moz-box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.34);
    box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.34);
    -webkit-transition: margin .6s, opacity .6s;
    -moz-transition: margin .6s, opacity .6s;
    -ms-transition: margin .6s, opacity .6s;
    -o-transition: margin .6s, opacity .6s;
    transition: margin .6s, opacity .6s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}

.flashForms .info-tip span:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 13px;
    border-top: 6px solid #333;
    border-top: 6px solid rgba(0, 0, 0, 0.8);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent
}

.flashForms .info-tip:hover,
.flashForms .info-tip:active,
.flashForms .info-tip:focus {
    overflow: visible;
    background: #000
}

.flashForms .info-tip:hover span,
.flashForms .info-tip:active span,
.flashForms .info-tip:focus span {
    opacity: 1;
    margin-top: 0
}

.flashForms .tooltip {
    position: absolute;
    z-index: 10;
    opacity: 0;
    color: #fff;
    width: 200px;
    left: -9999px;
    top: auto;
    font-size: 11px;
    background: #333;
    background: rgba(0, 0, 0, 0.8);
    -webkit-transition: margin .6s, opacity .6s;
    -moz-transition: margin .6s, opacity .6s;
    -ms-transition: margin .6s, opacity .6s;
    -o-transition: margin .6s, opacity .6s;
    transition: margin .6s, opacity .6s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}

.flashForms .tooltip > em {
    padding: 10px;
    font-style: normal;
    display: block;
    position: static
}

.flashForms .tooltip:after {
    content: '';
    position: absolute
}

.flashForms .input:focus + .tooltip,
.flashForms .textarea:focus + .tooltip {
    opacity: 1
}

.flashForms .tooltip.left {
    top: 1px;
    margin-right: -20px
}

.flashForms .tooltip.left:after {
    top: 12px;
    left: 100%;
    border-left: 6px solid #333;
    border-left: 6px solid rgba(0, 0, 0, 0.8);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent
}

.flashForms .input:focus + .tooltip.left,
.flashForms .textarea:focus + .tooltip.left {
    right: 100%;
    left: auto;
    margin-right: 5px
}

.flashForms .tooltip.right {
    top: 1px;
    margin-left: -20px
}

.flashForms .tooltip.right:after {
    top: 12px;
    right: 100%;
    border-right: 6px solid #333;
    border-right: 6px solid rgba(0, 0, 0, 0.8);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent
}

.flashForms .input:focus + .tooltip.right,
.flashForms .textarea:focus + .tooltip.right {
    left: 100%;
    margin-left: 5px
}

.flashForms .tooltip.right-top {
    bottom: 100%;
    margin-bottom: -20px
}

.flashForms .tooltip.right-top:after {
    top: 100%;
    right: 12px;
    border-top: 6px solid #333;
    border-top: 6px solid rgba(0, 0, 0, 0.8);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent
}

.flashForms .input:focus + .tooltip.right-top,
.flashForms .textarea:focus + .tooltip.right-top {
    right: 0;
    left: auto;
    margin-bottom: 10px
}

.flashForms .tooltip.left-top {
    bottom: 100%;
    margin-bottom: -20px
}

.flashForms .tooltip.left-top:after {
    top: 100%;
    left: 12px;
    border-top: 6px solid #333;
    border-top: 6px solid rgba(0, 0, 0, 0.8);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent
}

.flashForms .input:focus + .tooltip.left-top,
.flashForms .textarea:focus + .tooltip.left-top {
    left: 0;
    right: auto;
    margin-bottom: 10px
}

.flashForms .tooltip.right-bottom {
    top: 100%;
    margin-top: -20px
}

.flashForms .tooltip.right-bottom:after {
    bottom: 100%;
    right: 12px;
    border-bottom: 6px solid #333;
    border-bottom: 6px solid rgba(0, 0, 0, 0.8);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent
}

.flashForms .input:focus + .tooltip.right-bottom,
.flashForms .textarea:focus + .tooltip.right-bottom {
    right: 0;
    left: auto;
    margin-top: 10px
}

.flashForms .tooltip.left-bottom {
    top: 100%;
    margin-top: -20px
}

.flashForms .tooltip.left-bottom:after {
    bottom: 100%;
    left: 12px;
    border-bottom: 6px solid #333;
    border-bottom: 6px solid rgba(0, 0, 0, 0.8);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent
}

.flashForms .input:focus + .tooltip.left-bottom,
.flashForms .textarea:focus + .tooltip.left-bottom {
    right: auto;
    left: 0;
    margin-top: 10px
}

.flashForms .input[disabled],
.flashForms .textarea[disabled],
.flashForms .button[disabled],
.flashForms .options.disabled > .radio,
.flashForms .options.disabled > .checkbox,
.flashForms .disabled {
    background-color: #f9f9f9;
    cursor: not-allowed;
    opacity: .45
}


.res-image img {
    max-width: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.power-switch {
    z-index: 2;
    cursor: pointer;
    width: 1em;
    height: 1em;
    right: 20px;
    top: 15px;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    background: #000;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.7), 0 1px rgba(255, 255, 255, 0.15);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.7), 0 1px rgba(255, 255, 255, 0.15);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.7), 0 1px rgba(255, 255, 255, 0.15)
}

.power-switch::before {
    content: "";
    bottom: 15%;
    top: 15%;
    right: 15%;
    left: 15.5%;
    display: block;
    position: absolute;
    border-radius: 100%;
    border: 3px solid #0c9;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out
}

.power-switch::after {
    content: "";
    top: 9%;
    left: 47%;
    right: 47%;
    bottom: 60%;
    display: block;
    position: absolute;
    background: #0c9;
    -webkit-box-shadow: 0 0 0 3px rgba(13, 14, 14, 1);
    box-shadow: 0 0 0 3px rgba(13, 14, 14, 1);
    border-radius: 100%
}

.fxz {
    padding-top: 0
}


@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .flashForms .select:before,
    .flashForms .select:after {
        top: 36%
    }

    .flashForms .tooltip,
    .flashForms .options,
    .menu-wrappers,
    .flashForms .rating,
    .flashForms .toggle-switch {
        -webkit-animation: bugfix infinite 1s
    }

    @-webkit-keyframes bugfix {
        from {
            padding: 0
        }
        to {
            padding: 0
        }
    }
}

@media screen and (max-width: 600px) {
    #buy-button a {
        width: 100%;
    }

    .flashForms .lbl-text.tleft {
        padding-top: 0
    }

    .flashForms .lbl-valign,
    .flashForms .lbl-valign.long {
        padding-top: 8px
    }

    .colspacer-one {
        padding-top: 10px
    }

    .colspacer-two {
        padding-top: 20px
    }

    .flashForms .form-buttons .button {
        margin-bottom: 10px
    }

    .flashForms .form-buttons .button:last-child {
        margin-bottom: 0
    }

    .flashForms .option-group .goption,
    .flashForms .toggle-group .gtoggle {
        margin-bottom: 10px;
        margin-right: 0;
        display: block
    }

    .flashForms .option-group .goption:last-child,
    .flashForms .toggle-group .gtoggle:last-child {
        margin-bottom: 0
    }

    label.mobile-menu span {
        display: inline
    }

    .menu-wrappers {
        position: relative;
        z-index: 99
    }

    .spaced {
        padding-top: 70px
    }

    label.mobile-menu {
        position: fixed;
        display: block;
        width: 100%;
        cursor: pointer;
        background: #000;
        background: rgba(0, 0, 0, 0.9);
        padding: 10px 0;
        border-bottom: 1px solid white
    }

    label.mobile-menu:after {
        position: absolute;
        top: 0;
        right: 20px;
        content: "\2261";
        font-size: 30px;
        color: white
    }

    #demo-menu {
        display: none
    }

    #mobile-menu:checked + #demo-menu {
        display: block;
        left: auto;
        top: 48px;
        right: 10px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px
    }

    #demo-menu:after {
        content: '';
        position: absolute;
        left: auto !important;
        top: auto !important;
        bottom: 100% !important;
        right: 12px !important;
        border-bottom: 10px solid #333 !important;
        border-bottom: 10px solid rgba(0, 0, 0, 0.9) !important;
        border-right: 10px solid transparent !important;
        border-left: 10px solid transparent !important;
        border-top: 0
    }

    #demo-menu a.last {
        -webkit-border-radius: 0 0 5px 5px;
        -moz-border-radius: 0 0 5px 5px;
        border-radius: 0 0 5px 5px
    }
}

@media screen and (max-width: 380px) {
    .flashForms .form-buttons {
        text-align: center
    }

    .flashForms .form-buttons .button {
        width: 100%;
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .flashForms span.lbl-text {
        width: 100%;
        text-align: right
    }
}

.fehler1 {
    box-shadow: 0 0 10px red;
    padding: 0
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 10px #ff0000;
    }
    40% {
        box-shadow: 0 0 10px #ff0000;
    }
    60% {
        box-shadow: 0 0 10px #ff0000;
    }
    100% {
        box-shadow: 0 0 10px #ff0000;
    }
}

.fehler {
    -webkit-animation: glowing 1s 3;
    animation: glowing 1s 3
}











